h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
strong {
	padding: 0;
	margin: 0;
	font-family: 'Fira Sans', sans-serif;
}

* {
	font-family: 'Fira Sans', sans-serif;
}

@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	}

	@else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	}

	@else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	}

	@else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	}

	@else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	}

	@else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	}

	@else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	}

	@else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	}

	@else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	}

	@else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
	--title: #ffffff;
	--title-rgb: 255, 255, 255;
	--text: #ffffff;
	--body: #0d0b06;
	--section: #fefeff;
	--white: #ffffff;
	--base: #ffc100;
	--base-2: #162c67;
	--body-fonts: "Fira Sans", sans-serif;
	--heading-fonts: "Fira Sans", sans-serif;
	--border: #e5e5e5;
	--gradient: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
}

body {
	background: var(--body);
	font-family: var(--body-fonts);
	color: var(--text);
	font-weight: normal;
	font-size: 18px;

	@include breakpoint(max-sm) {
		font-size: 14px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--title);
	font-family: var(--heading-fonts);
	font-weight: 500;
}

h1 {
	font-size: 54px;
}

h2 {
	font-size: 2.7rem;
}

h3 {
	font-size: 37px;
}

h4 {
	font-size: 30px;
}

h5 {
	font-size: 24px;
}

h6 {
	font-size: 20px;
}

@include breakpoint(max-md) {
	h1 {
		font-size: 34px;
	}

	h2 {
		font-size: 28px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 22px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 16px;
	}
}

@include breakpoint(max-sm) {
	h1 {
		font-size: 28px;
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 22px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 16px;
	}
}

ul {
	padding: 0;
	margin: 0;

	li {
		list-style: none;
	}
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
	transition: all ease 0.3s;
}

.font-light {
	font-weight: 300;
}

.font-regular {
	font-weight: 400;
}

.font-medium {
	font-weight: 500;
}

.font-semibold {
	font-weight: 600;
}

.font-bold {
	font-weight: 700;
}

.font-extra-bold {
	font-weight: 800;
}

.font-black {
	font-weight: 900;
}

.container {
	position: relative;
	z-index: 1;
}

@include breakpoint(xxl) {
	.container {
		max-width: 1350px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.bg-section {
	background: var(--section) !important;
}

.bg-body {
	background: var(--body) !important;
}


.dark{
	.educator-slider{
		.course-items .body-content {
			background-color: rgba(51, 39, 12, 1);
			border-radius: 0 0 26px 26px;
			.course-title{
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
				letter-spacing: 0em;
				text-align: left;
				color:#fff;
			}
			.course-details{
				font-size: 14px;
				font-weight: 400;
				line-height: 19px;
				letter-spacing: 0em;
				text-align: left;
				color:	rgba(225, 225, 225, 1);
			}
			p{
				color: #000000;
			}
			.profile-btn{
				padding: 8px 18px;
				border-radius: 6px;
				background:rgba(255, 193, 0, 1);
				font-size: 14px;
				font-weight: 400;
				line-height: 19px;
				letter-spacing: 0em;
				text-align: center;
				color:rgba(255, 241, 241, 1) !important;
			}
			.subscribe-btn{
				margin-left: 5px;
				padding: 8px 18px;
				border-radius: 6px;
				background:transparent;
				font-size: 14px;
				font-weight: 400;
				line-height: 19px;
				letter-spacing: 0em;
				text-align: center;
				color: rgba(255, 193, 0, 1)!important;
				border: 1px solid rgba(255, 193, 0, 1) !important;
			}
		}

		 .course-items .body-content .course-time p{
			color: #000000;
		}
	}

	.dark_mode{
		display: block;
	}
	.light_mode{
		display: none;
	}
	.light-logo{
		display: none;
	}
	.dark-logo{
		display: block;
	}
		.auth-layout {
			.auth-left {
				height: 100vh;
				min-height: 660px;
				position: relative;
				overflow: hidden;
				padding: 47px 82px;
				position: sticky;
				top: 0;
				.logo {
					display: inline-block;
					position: relative;
				}
				.main-img {
					position: absolute;
					top: 50%;
					left: 55%;
					transform: translate(-50%, -50%);
					max-width: 100%;
				}
				.step-items {
					position: absolute;
					left: 45%;
					width: 100%;
					transform: translateX(-50%);
					bottom: 60px;
					max-width: 400px;
				}
			}
			.auth-right {
				width: 95% !important;
				background: var(--gradient);
				border-radius: 42px;
				border: 1px solid #ffbf0041;
				position: relative;
				padding: 35px 50px;
				z-index: 1;
				.auth-header {
					margin-bottom: 36px;
					@include breakpoint(max-xl) {
						@include breakpoint(lg) {
							.title {
								font-size: 2rem;
							}
						}
					}
				}
				@media screen and (max-width: 1500px) {
					padding: 37px 32px 37px 43px;
					margin: 37px 44px 37px 0px;
					width: calc(50% - 10px);
				}
			}
			@include breakpoint(max-lg) {
				flex-wrap: wrap;
				background-position: left center !important;
				padding-bottom: 24px;
				.auth-left,
				.auth-right {
					width: 100%;
					margin: 0;
				}
				.auth-left {
					min-height: 0;
					height: 750px;
					position: relative;
					.main-img {
						max-width: 700px;
						@include breakpoint(max-sm) {
							max-width: 600px;
						}
						@media screen and (max-width: 575px) {
							left: 64%;
						}
						@media screen and (max-width: 500px) {
							left: 62%;
						}
						@media screen and (max-width: 520px) {
							left: 68%;
						}
						@media screen and (max-width: 375px) {
							left: 70%;
						}
						@media screen and (max-width: 320px) {
							left: 76%;
						}
					}
					@include breakpoint(max-sm) {
						.step-items {
							left: 0;
							transform: translate(0);
							width: 100%;
							justify-content: center;
							max-width: 100%;
							padding-inline: 24px;
							img {
								max-width: 100%;
							}
						}
					}
				}
				.auth-right {
					margin-inline: 24px;
					padding-inline: 24px;
				}
			}
		}

		.form--label {
			color: var(--base);
			display: block;
			margin-bottom: 5px;
		}

		.form--control {
			height: 50px;
			border-radius: 5px;
			border: 1px solid var(--base);
			background-color: transparent;
			box-shadow: none !important;
			outline: none;
			color: var(--base) !important;
			padding: 13px;
			&::placeholder {
				color: #988138;
			}
			&:focus {
				background-color: transparent;
				border: 1px solid var(--base);
			}
			@include breakpoint(max-xl) {
				@include breakpoint(lg) {
					height: 50px;
				}
			}
		}

		.eye {
			position: absolute;
			right: 0;
			top: 0;
			width: 60px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			z-index: 9;
			@include breakpoint(max-xl) {
				@include breakpoint(lg) {
					height: 50px;
					width: 50px;
				}
			}
		}
		.form--check {
			cursor: pointer;
			.uncheck-icon {
				width: 22px;
				height: 22px;
				display: inline-block;
				border: 2px solid rgba(#ffffff, 0.6);
				top: 50%;
				left: 0;
				position: absolute;
				transform: translateY(-38%);
			}
			padding: 0;
			.icon {
				width: 28px;
				position: relative;
			}
			display: flex;
			align-items: center;
			gap: 5px;
			margin: 0;
		}
		.submit-btn {
			border-radius: 5px;
			border: 1px solid var(--base);
			background: rgba(255, 193, 0, 0.2);
			width: 100%;
			color: var(--white);
			height: 50px;
		}
		.text-underline {
			text-decoration: underline;
		}
		.text-base {
			color: var(--base);
		}
		.plan--row {
			@include breakpoint(xxl) {
				--bs-gutter-x: 35px;
				--bs-gutter-y: 35px;
			}
		}
		.plan-item {
			background: var(--base);
			border-radius: 40px;
			padding: 24px 27px 40px;
			.plan-title {
				font-size: 30px;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 22px;
				color: #000;
				.name {
					color: #000;
				}
				.badge {
					padding: 6px 10px;
					font-size: 18px;
					font-weight: 400;
					color: #000;
					background: var(--white);
				}
			}
			.plan-price {
				padding-bottom: 16px;
				border-bottom: 1px solid #2e2e2e;
				margin-bottom: 44px;
				.price {
					display: flex;
					align-items: center;
					gap: 2px;
					font-size: 24px;
					font-weight: 700;
					color: #000;
					.month {
						font-size: 10px;
						font-weight: 400;
					}
					.currency {
						font-size: 16px;
						font-weight: 400;
					}
				}
			}
			.plan-info {
				margin-bottom: 48px;
				li {
					display: flex;
					align-items: center;
					gap: 11px;
					&:not(:last-child) {
						margin-bottom: 21px;
					}
					color: #000000;
					font-size: 16px;
					font-weight: 400;
					strong {
						font-weight: 500;
					}
					svg {
						width: 18px;
						height: 18px;
					}
					span {
						width: 0;
						flex-grow: 1;
					}
				}
			}
			.plan-btn {
				border-radius: 11px;
				border: 1px solid #000;
				background: #fff;
				width: 162px;
				min-height: 44px;
				padding: 14px 10px;
				text-align: center;
				font-weight: 500;
				font-size: 13px;
				color: #000;
				transition: all ease 0.3s;
				&:hover {
					background: var(--base);
					// border-color: var(--white);
					// color: var(--white);
				}
			}
			@include breakpoint(max-sm) {
				padding: 24px 15px 30px;
				.plan-info {
					font-size: 14px;
					margin-bottom: 24px;
				}
			}
		}
		.text--label-white {
			color: var(--white);
			.form-label,
			.form--control {
				color: var(--white) !important;
			}
		}
		.verify-button {
			color: #fff;
			text-align: center;
			font-family: Fira Sans;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			border-radius: 11px;
			background: linear-gradient(95deg, #f5bd00 -0.91%, #995400 99.33%);
			height: 68px;
			padding-inline: 38px;
			border: none;
			outline: none;
			box-shadow: none;
			@include breakpoint(max-sm) {
				font-size: 16px;
				height: 50px;
				padding-inline: 24px;
			}
		}
		.dashboard-layout {
			display: flex;
			min-height: 100dvh;
			.dashboard-sidebar {
				background: #1b1609;
				width: 258px;
				height: 100dvh;
				position: sticky;
				top: 0;
				.logo {
					padding: 18px 25px;
					a {
						display: block;
						img {
							width: 100%;
						}
					}
				}
				display: flex;
				flex-direction: column;
				transition: all ease 0.3s;
				z-index: 999;
				@include breakpoint(lg) {
					&:not(.active) {
						width: 86px;
						.aside-bottom {
							display: none;
						}
						.sidebar-menu {
							a {
								padding-inline: 0;
								justify-content: center;
								.name {
									display: none;
								}
							}
						}
					}
				}
				@include breakpoint(max-lg) {
					position: fixed;
					top: 0;
					left: 0;
					&.active {
						visibility: hidden;
						transform: translateX(-100%);
					}
				}
			}
			.dashboard-content {
				flex-grow: 1;
				width: 200px;
				position: relative;
				z-index: 1;
				padding: 0 28px 42px;
				padding-bottom: 0 !important;
				overflow: hidden;
				@include breakpoint(max-lg) {
					width: 100%;
					padding: 0 14px 42px;
				}
			}
		}
		.sidebar-menu {
			margin-top: 41px;
			padding: 0 16px;
			display: flex;
			flex-direction: column;
			gap: 20px;

			margin-inline: auto;
			max-width: 224px;
			width: 100%;

			a {
				display: flex;
				align-items: center;
				padding: 13px 16px;
				gap: 10px;
				color: #887432;
				font-size: 16px;
				&.active {
					color: var(--white);
					border-radius: 7px;
					border: 1px solid #fec44f;
					background: #452e00;
					box-shadow: 0px 6px 19px 0px rgba(255, 193, 0, 0.33);
				}
			}
		}
		.toggle-button {
			position: fixed;
			bottom: 0;
			left: 0;
			background: transparent;
			width: 45px;
			height: 45px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			box-shadow: none;
			border-radius: 3px 1px 1px 3px;
			transform: rotate(0deg);
			z-index: 999;
			left: 85px;
			overflow: hidden;
			&.active {
				left: 258px;
				border-radius: 3px 1px 1px 3px;
				transform: rotate(180deg);
			}
			transition: all ease 0.3s;
			@include breakpoint(max-lg) {
				transform: rotate(180deg);
				left: 258px;
				&.active {
					left: 0;
					transform: rotate(0deg);
				}
			}
			&::after,
			&::before {
				position: absolute;
				content: "";
				inset: 0;
				background: var(--body);
				z-index: -1;
			}
			&::after {
				background: linear-gradient(41deg, rgba(55, 43, 4, 0.4) -9.22%, rgba(255, 170, 0, 0.4) 63.27%);
				z-index: 1;
			}
		}
		.aside-bottom {
			border-radius: 20px;
			background: linear-gradient(108deg, #fa0 7.37%, #b68a00 95.19%);
			box-shadow: 0px -24px 18px 0px rgba(254, 196, 79, 0.08);
			.inner {
				border-radius: 20px;
				.subtitle {
					max-width: 163px;
					margin: 0 auto 35px;
					font-size: 1rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 0.16px;
					text-align: center;
				}
				.pro-button {
					border-radius: 20px;
					background: #fff;
					box-shadow: 2px 4px 4px 0px rgba(79, 42, 234, 0.17);
					height: 40px;
					border: none;
					outline: none;
					width: 100%;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					color: var(--base);
				}
				padding: 40px 28px 22px;
			}
		}
		.user-dropdown {
			.btn {
				background: transparent !important;
				padding: 0;
				border: none;
				display: flex;
				align-items: center;
				gap: 5px;
				img {
					border-radius: 50%;
					object-fit: cover;
					aspect-ratio: 1;
				}
			}
			@include breakpoint(max-lg) {
				.btn {
					&::after {
						display: none;
					}
				}
			}
		}
		.header-section {
			display: flex;
			align-items: center;
			gap: 20px;
			padding-block:10px 0;
			padding-bottom: 10px;
			font-size: 16px;
			position: sticky;
			top: 0;
			z-index: 99;
			transition: all ease 0.3s;
			// &.active {
			// 	background: rgba(#0d0b06, 0.95);
			// 	padding-block: 16px;
			// }
			.title {
				margin: 0;
				@include breakpoint(xxl) {
					font-size: 40px;
				}
			}
			.header-right {
				display: flex;
				align-items: center;
				gap: 20px;
				@include breakpoint(max-sm) {
					gap: 10px;
					.premium-btn {
						font-size: 13px;
					}
					.user-dropdown {
						.btn {
							img {
								width: 30px;
								height: 30px;
							}
						}
					}
				}
			}

			@include breakpoint(max-sm) {
				gap: 10px;
			}
		}
		.search-form {
			flex-grow: 1;
			width: 200px;
			max-width: 732px;
			margin: 0 auto;
			font-size: 16px;
			.form-control {
				height: 42px;
				background: rgba(255, 255, 255, 0.129);
				// color: var(--base);
				color: #936200;
				border: 1px solid #ffbf00a0;
				border-top-color: #936200;
				border-right-color: #936200;
				border-left-color: #936200;
				box-shadow: none;
				&::placeholder {
					color: #936200;
				}
				padding-left: 45px;
				@include breakpoint(max-sm) {
					height: 35px;
					font-size: 14px;
					padding-left: 35px;
				}
			}
			.submit--btn {
				position: absolute;
				top: 0;
				left: 0;
				height: 42px;
				width: 42px;
				background: transparent;
				border: none;
				outline: none;
				box-shadow: none;
				@include breakpoint(max-sm) {
					height: 35px;
					width: 35px;
				}
			}
		}
		.dasboard-bg {
			position: absolute;
			top: 0;
			left: 0;
			height: 100dvh;
			width: 100vw;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			img {
				position: absolute;
				// max-width: 914px;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.premium-btn {
			border-radius: 7px;
			background: rgba(20, 255, 0, 0.28);
			padding: 7px 10px;
			display: flex;
			align-items: center;
			border: none;
			outline: none;
			box-shadow: none;
			color: #92da70;
			font-size: 16px;
			gap: 4px;
		}
		.section-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			height: 80vh;
			.item-left {
				width: 100%;
				position: relative;
				z-index: 1;
				@include breakpoint(sm) {
					max-width: 430px;
				}
			}
			.item-right {
				width: 100%;
				border-radius: 42px;
				border: 1px solid #ffc100;
				background: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
				padding: 36px 26px 24px;
				position: relative;
				z-index: 1;
				@include breakpoint(sm) {
					max-width: 328px;
				}
			}
			@include breakpoint(max-xl) {
				.item-left {
					max-width: 100%;
				}
				.location-map {
					width: 100%;
					height: 100%;
					img {
						top: 62%;
					}
				}
				.item-right {
					max-width: 100%;
				}
			}
			@include breakpoint(max-sm) {
				.location-map {
					height: 00px;
				}
			}
		}
		.total-member {
			border-radius: 35px;
			border: 1px solid #ffc100;
			background: var(
				--Main-Element-Gradient,
				linear-gradient(105deg, rgba(55, 43, 4, 0.4) -0.46%, rgba(254, 196, 79, 0.4) 95.66%)
			);
			backdrop-filter: blur(10.5px);
			padding: 28px;
			font-size: 16px;
			.subtitle {
				flex-grow: 1;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.8px;
				color: var(--base);
				position: relative;
				z-index: 1;
			}
			.form-select {
				width: unset;
				padding: 0;
				padding-right: 12px;
				// background-color: transparent;
				color: var(--white);
				border: none;
				box-shadow: none;
				background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e")
					no-repeat right center / 14px;
				text-transform: uppercase;
				option {
					color: var(--body);
				}
				position: relative;
				z-index: 1;
			}
			.form-select-read-more {
				width: unset;
				padding: 0;
				padding-right: 12px;
				background-color: transparent;
				color: var(--white);
				border: none;
				box-shadow: none;
				text-transform: uppercase;
				option {
					color: var(--body);
				}
				position: relative;
				z-index: 1;
			}
			.title {
				font-size: 48px;
				margin-top: 16px;
				position: relative;
				z-index: 1;
				span {
					font-weight: 400;
				}
			}
			position: relative;
			.arrow {
				position: absolute;
				top: 64px;
				right: 30px;
			}
			margin-bottom: 24px;
			@include breakpoint(xxl) {
				margin-bottom: 36px;
			}
		}
		.top-affiliates {
			.title {
				font-size: 24px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				letter-spacing: 0.96px;
				margin-bottom: 16px;
				@include breakpoint(xxl) {
					margin-bottom: 24px;
				}
			}
		}
		.text-success {
			color: #0da300 !important;
		}
		.affiliates {
			display: flex;
			flex-direction: column;
			gap: 18px;
			.affiliate-item {
				display: flex;
				align-items: center;
				gap: 20px;
				color: #fff;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.64px;
				.img {
					width: 56px;
					height: 56px;
					border-radius: 13px;
					object-fit: cover;
					aspect-ratio: 1;
				}
				.cont {
					padding-top: 5px;
				}
				.name {
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.64px;
					flex-grow: 1;
					margin-bottom: 6px;
				}
				.amount {
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.64px;
					color: var(--base);
				}
			}
		}
		.stock-market {
			border: 1px solid #ffc100;
			background: var(
				--Main-Element-Gradient,
				linear-gradient(105deg, rgba(55, 43, 4, 0.4) -0.46%, rgba(254, 196, 79, 0.4) 95.66%)
			);
			backdrop-filter: blur(10.5px);
			padding: 24px 18px;
			border-radius: 42px;
			margin-top: 24px;
			@include breakpoint(xxl) {
				margin-top: 36px;
			}
			.title {
				color: #ffc100;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.8px;
				margin-bottom: 21px;
			}
		}
		.market {
			display: flex;
			flex-direction: column;
			gap: 14px;
			--special-clr: #50ff00;
			.market-item {
				border-radius: 12px;
				background: linear-gradient(93deg, rgba(55, 43, 4, 0.12) -7.39%, rgba(255, 170, 0, 0.12) 2.35%);
				display: flex;
				align-items: center;
				padding: 11px 17px;
				gap: 17px;
				.name {
					font-size: 20px;
					font-style: normal;
					font-weight: 500;
					width: 40px;
					text-align: center;
					margin-right: 17px;
					line-height: 1;
					color: var(--special-clr);
				}
				font-size: 16px;
				.profit,
				.time {
					color: var(--base);
				}
				.price {
					font-size: 32px;
					font-style: normal;
					font-weight: 500;
				}
				.price {
					margin: 0;
				}
				position: relative;
				.arrow {
					color: var(--special-clr);
					position: absolute;
					left: calc(50% + 26px);
					bottom: 36px;
					svg {
						width: 85px;
					}
				}
			}
		}
		.w-0 {
			width: 0;
		}
		.schedules {
			display: flex;
			flex-direction: column;
			gap: 20px;
			.schedule-item {
				border-radius: 12px;
				background: linear-gradient(93deg, rgba(55, 43, 4, 0.12) -7.39%, rgba(255, 170, 0, 0.12) 2.35%);
				padding: 7px;
				color: var(--base);
				font-size: 12px;
				.time {
					text-transform: uppercase;
				}
				.name {
					color: #fff;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					margin-top: 5px;
					margin-bottom: 11px;
				}
				display: flex;
				gap: 8px;
				.img {
					border-radius: 12px;
					width: 79px;
				}
				.cont {
					width: 0;
					flex-grow: 1;
				}
				.cont,
				img {
					transition: all ease 0.3s;
				}
				&:hover {
					.cont,
					img {
						opacity: 0.6;
					}
				}
			}
		}
		.inline-date {
			.react-datepicker {
				width: 100%;
				background: transparent;
				.react-datepicker__month {
					margin-inline: 0;
				}
				.react-datepicker__month-container {
					width: 100%;
					.react-datepicker__current-month {
						color: var(--white);
					}
				}
				.react-datepicker__day-names {
					display: flex;
					padding-top: 20px;
					.react-datepicker__day-name {
						color: var(--base);
						flex-grow: 1;
						width: 0;
					}
				}
				.react-datepicker__header {
					background: transparent;
					border: none;
					.react-datepicker__current-month {
						font-size: 20px;
						transform: translateY(-11px);
					}
				}
				text-align: center;
				border: none;
				.react-datepicker__day {
					color: var(--white);
					&:hover {
						background: rgba(255, 193, 0, 0.33) !important;
					}
				}
				.react-datepicker__day--disabled {
					color: #808080;
				}
				.react-datepicker__day--keyboard-selected,
				.react-datepicker__day--selected {
					border-radius: 7px !important;
					background-color: rgba(255, 193, 0, 0.33) !important;
				}
				.react-datepicker__week {
					display: flex;
				}
				.react-datepicker__day {
					margin-block: 7px;
					flex-grow: 1;
					width: 0;
				}
				.react-datepicker__navigation {
					color: var(--base);
					width: 22px;
					height: 22px;
					border: 1px solid var(--base);
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 5px;
					.react-datepicker__navigation-icon {
						&::before {
							border-color: var(--base);
							border-width: 1px 1px 0 0;
						}
						left: 0;
					}
				}
				.react-datepicker__year-read-view--down-arrow,
				.react-datepicker__month-read-view--down-arrow,
				.react-datepicker__month-year-read-view--down-arrow,
				.react-datepicker__navigation-icon::before {
					top: 12px;
				}
			}
		}
		.events-date {
			display: flex;
			align-items: center;
			gap: 10px;
			.day {
				border-radius: 5px;
				background: #fffefc;
				width: 43px;
				height: 43px;
				.num {
					color: #010101;
					font-size: 24px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
				text-align: center;
				color: #010101;
				text-transform: uppercase;
			}
			font-size: 12px;
			line-height: normal;
			font-style: normal;
			font-weight: 400;
			.cont {
				h6 {
					color: #fff;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin-bottom: 2px;
				}
			}
		}
		.location-map {
			width: 0;
			flex-grow: 1;
			position: relative;
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-52%, -60%);
				max-width: 70vw;
			}
			@media screen and (max-width: 1750px) {
				img {
					max-width: 70vw;
					top: 60%;
				}
			}
			@media screen and (max-width: 1440px) {
				img {
					max-width: 70vw;
					top: 40%;
				}
			}
			@media screen and (max-width: 992px) {
				img {
					max-width: 90vw;
				}
			}
			@media screen and (max-width: 575px) {
				img {
					max-width: 130vw;
				}
			}
		}
		.dashboard-bg {
			position: fixed;
			top: 0;
			left: 0;
			height: 100dvh;
			width: 100vw;
			opacity: 0.3;
		}
		.form--select-control {
			border-radius: 6px;
			background: rgba(255, 193, 0, 0.4);
			.form--control {
				background-color: transparent;
				--bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
				background-size: 16px;
				height: 39px;
				font-size: 16px;
				padding-block: 0;
				font-weight: 400;
				border: none;
				padding-inline: 17px 37px;
			}
			&.text {
				background-color: transparent;
				border: none;
				.form-select {
					--bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffc100%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
					padding-left: 0;
				}
			}
			.icon-base.form-select {
				--bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffc100%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
			}
		}
		.tutor-slider-wrapper {
			border-radius: 28px;
			border: 1px solid var(--base);
			background: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
			padding: 45px;
			@include breakpoint(max-xxl) {
				padding: 35px;
			}
			@include breakpoint(max-xl) {
				padding: 35px 25px;
			}
			@include breakpoint(max-xl) {
				padding: 35px 20px;
			}
			.top {
				display: flex;
				flex-wrap: wrap;
				gap: 20px;
				align-items: center;
				justify-content: space-between;
				.title {
					margin: 0;
				}
				margin-bottom: 52px;
				@include breakpoint(max-xl) {
					margin-bottom: 36px;
				}
				@include breakpoint(max-md) {
					margin-bottom: 25px;
				}
			}
		}
		.tutor-slider-item {
			border: 1px solid var(--base);
			border-radius: 24px;
			padding: 14px 10px 24px;

			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			.img {
				border-radius: 21px;
				overflow: hidden;
				margin-bottom: 26px;
				img {
					width: 100%;
					aspect-ratio: 315/209;
				}
				position: relative;
				.wishlist {
					position: absolute;
					right: 20px;
					top: 20px;
					color: var(--base);
				}
			}
			.name {
				margin-bottom: 18px;
			}
			@include breakpoint(max-md) {
				font-size: 14px;
			}
			@include breakpoint(max-sm) {
				.img {
					margin-bottom: 20px;
				}
				.name {
					margin-bottom: 12px;
				}
			}
		}
		.no-gutter {
			background: transparent;
			--bs-gutter-x: 0;
			--bs-gutter-y: 0;
			border: none;
			outline: none;
			box-shadow: none;
		}
		.slider-wrapper {
			position: relative;
			.arrow-left,
			.arrow-right {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			.arrow-left {
				left: -32px;
			}
			.arrow-right {
				right: -32px;
			}
			@include breakpoint(max-xl) {
				padding-inline: 16px;
				.arrow-left {
					left: -16px;
				}
				.arrow-right {
					right: -16px;
				}
			}
		}
		.mt-lg-55 {
			@include breakpoint(lg) {
				margin-top: 55px !important;
			}
		}

		.affiliate-post-wrapper {
			display: flex;
			flex-direction: column;
			gap: 24px;
			@include breakpoint(lg) {
				gap: 55px;
			}
		}
		.affialite-post {
			display: flex;
			flex-wrap: wrap;
			.img {
				width: 25%;
				max-width: 479px;
				border-radius: 28px 0 0 28px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.cont {
				width: 0;
				flex-grow: 1;
				border-radius: 0 28px 28px 0;
				border: 1px solid #ffc100;
				background: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
				padding: 58px 52px;
				border-left: none;
				.author {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					@include breakpoint(xl) {
						margin-bottom: 28px;
					}
					@include breakpoint(xxl) {
						margin-bottom: 44px;
					}
					img {
						width: 92px;
						aspect-ratio: 1;
						object-fit: cover;
						@include breakpoint(max-sm) {
							width: 75px;
						}
					}
					.name {
						@include breakpoint(xxl) {
							font-size: 32px;
						}
						//line limit 2
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						margin: 0;
						width: 0;
						flex-grow: 1;
					}
					gap: 20px;
					@include breakpoint(xxl) {
						gap: 30px;
					}
				}
				.txt {
					font-size: 18px;
					@include breakpoint(xxl) {
						font-size: 32px;
						line-height: 1.2;
					}
					//line limit 2
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin: 0;
					font-weight: 400;
				}
			}
			@include breakpoint(max-xxl) {
				.cont {
					padding: 35px 25px;
					.author {
						margin-bottom: 25px;
					}
				}
			}
			@include breakpoint(max-sm) {
				.img {
					border-radius: 20px 20px 0 0;
					width: 100%;
					max-width: 100%;
				}
				.cont {
					width: 100%;
					border-radius: 0 0 20px 20px;
					border-top: none;
					border-left: 1px solid var(--base);
				}
			}
		}
		.outline-btn,
		.base-btn {
			height: 49px;
			padding-inline: 13px;
			border-radius: 11px;
			border: 1px solid #ffc100;
			display: flex;
			justify-content: center;
			gap: 9px;
			align-items: center;
			color: var(--white);
			@include breakpoint(max-sm) {
				height: 38px;
				font-size: 14px;
				svg {
					width: 20px;
					height: unset;
				}
			}
		}
		.meta-area {
			margin-top: 28px;
			font-size: 16px;
			.text-small {
				font-size: 14px;
			}
			@include breakpoint(xxl) {
				margin-top: 45px;
				font-size: 20px;
				.text-small {
					font-size: 16px;
				}
			}
			@include breakpoint(max-sm) {
				font-size: 13px;
				.text-small {
					font-size: 11px;
				}
			}
		}
		.base-btn {
			background: var(--base);
		}
		.affiliate-row {
			@include breakpoint(xxl) {
				--bs-gutter-x: 34px;
				--bs-gutter-y: 30px;
			}
		}
		.__card {
			border-radius: 28px;
			border: 1px solid rgba(#ffc100, 0.5);
			border-top: 1px solid #936200;
			background: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
			padding: 37px;
			@include breakpoint(max-xxl) {
				padding: 35px 20px;
			}
			height: 100%;
		}
		.mt-30 {
			margin-top: 30px;
		}
		.mb-40 {
			margin-bottom: 40px;
		}
		.copy-input {
			.form-control {
				height: 50px;
				border-radius: 5px;
				border: 1px solid var(--base);
				outline: none;
				box-shadow: none;
				background: transparent;
				color: var(--white);
				padding-right: 60px;
			}
			button {
				position: absolute;
				right: 0;
				top: 0;
				height: 50px;
				width: 50px;
				background: transparent;
				outline: none;
				box-shadow: none;
				border: none;
			}
		}
		.text-16 {
			font-size: 16px !important;
		}
		.balance-card {
			display: flex;
			align-items: center;
			gap: 26px;
			padding: 12px 18px;
			.inner-card {
				border-radius: 23px;
				// border: 1px solid var(--base);
				background: rgba(#d9d9d9, 0.05);
				padding: 19px;
				text-align: center;
				flex-grow: 1;
				.balance {
					font-style: normal;
					font-weight: 700;
					margin: 0;
					line-height: 1;
					font-size: 30px;
					@include breakpoint(xl) {
						font-size: 32px;
					}
					@include breakpoint(xxl) {
						font-size: 2.4vw;
					}
				}
			}
			@include breakpoint(max-xxl) {
				gap: 14px;
				img {
					width: 60px;
				}
				.inner-card {
					padding: 14px;
					.text-16 {
						font-size: 14px !important;
						font-weight: 500;
					}
					.balance {
						font-size: 24px;
					}
				}
			}
			@media screen and (max-width: 450px) {
				gap: 14px;
				padding: 14px;
				img {
					width: 40px;
				}
				.inner-card {
					padding: 12px;
					.text-16 {
						font-size: 11px !important;
					}
					.balance {
						font-size: 20px;
					}
				}
			}

			@media screen and (max-width: 350px) {
				img {
					margin: 0 auto;
					width: 40px;
				}
				flex-wrap: wrap;
				.inner-card {
					width: 100%;
					max-width: 100%;
					border-radius: 20px;
					.text-16 {
						font-size: 11px !important;
					}
					.balance {
						font-size: 20px;
					}
				}
			}
		}
		.table-card-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 20px;
			flex-wrap: wrap;
			.form--select-control {
				border-radius: 7px;
				border: 1px solid #ffc100;
				background-color: transparent !important;
				.form-select {
					text-transform: capitalize;
					background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffc100%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e")
						no-repeat calc(100% - 14px) center / 14px;
				}
			}
		}
		.__table {
		}
		body * {
			::-webkit-scrollbar {
				width: 4px;
				height: 4px;
			}
			::-webkit-scrollbar-track {
				background: #ffffff30;
			}
			::-webkit-scrollbar-thumb {
				background: #ffc100;
				border-radius: 10px;
			}
			::-webkit-scrollbar-thumb:hover {
				background: #ffc100;
			}
		}
		.table-bottom-bordered {
			min-width: 500px;
			margin: 0;
			tr {
				th {
					font-size: 16px;
					background: transparent;
					color: var(--base);
					font-weight: 400;
					border: none;
					padding: 20px 0;
				}
				td {
					font-size: 16px;
					border-bottom: 1px solid #734f06;
					padding: 20px 0;
					a {
						background: transparent;
						color: var(--white);
						&:hover {
							color: var(--base);
						}
					}
					&.border-0 {
						padding: 12px 0;
					}
				}
				.badge {
					height: 31px;
					min-width: 81px;
					color: #3f260e;
					border-radius: 9px;
					background: #ad8300;
				}
			}
		}
		.feed-row {
			@include breakpoint(xxl) {
				--bs-gutter-x: 49px;
			}
		}
		.post-form {
			.form-control {
				border-radius: 18px;
				border: 1px solid rgba(#ffc100, 0.1);
				background: rgba(#ffc100, 0.1);
				min-height: 208px;
				resize: none;
				box-shadow: none;
				outline: none;
				padding: 40px 40px 65px 81px;
				color: var(--white);
				&::placeholder {
					color: #a7a7a7;
				}
			}
			.post-author {
				position: absolute;
				width: 42px;
				aspect-ratio: 1;
				object-fit: cover;
				border-radius: 50%;
				top: 31px;
				left: 28px;
			}
			@include breakpoint(max-sm) {
				.post-author {
					width: 30px;
					left: 20px;
					top: 20px;
				}
				.form-control {
					font-size: 14px;
					padding-top: 24px;
					padding-left: 60px;
					padding-right: 20px;
				}
			}
			.send-form-bottom {
				position: absolute;
				right: 0;
				bottom: 0;
				padding-bottom: 23px;
				width: 100%;
				padding-inline: 29px 37px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				z-index: 1;
				gap: 13px;
				align-items: center;
				.send-btn {
					margin-left: auto;
				}
				@include breakpoint(max-sm) {
					padding: 0 15px 15px;
					gap: 10px;
					.send-btn {
						padding: 7px 14px;
						font-size: 14px;
						svg {
							width: 16px;
						}
					}
				}
			}
		}
		.cursor-pointer {
			cursor: pointer;
		}
		.send-btn {
			border-radius: 12px;
			background: #fff;
			padding: 7px 23px;
			color: #000;
			display: flex;
			justify-content: center;
			gap: 7px;
			align-items: center;
			font-size: 16px;
			outline: none;
			box-shadow: none;
			border: none;
			font-weight: 400;
		}
		.post-form-card {
			@include breakpoint(max-xl) {
				padding: 24px;
			}
			@include breakpoint(max-md) {
				padding: 16px 16px 24px;
			}
		}
		.no--gutter {
			background: transparent !important;
			&::after {
				display: none;
			}
			padding: 0;
			margin: 0;
		}
		.dropdown-menu,
		:root {
			--bs-dropdown-link-active-bg: var(--base);
		}
		.post-item-area {
			display: flex;
			flex-direction: column;
			gap: 30px;
			.post-item {
				padding-bottom: 30px;
				border-bottom: 1px solid #988138;
			}
			@include breakpoint(xl) {
				gap: 60px;
				.post-item {
					padding-bottom: 60px;
				}
			}
		}
		.post-item {
			&-top {
				display: flex;
				justify-content: space-between;
				gap: 8px;
				margin-bottom: 25px;
				.img {
					width: 42px;
					position: relative;
					.icon {
						position: absolute;
						bottom: -3px;
						right: -3px;
					}
					img {
						width: 100%;
						aspect-ratio: 1;
						object-fit: cover;
					}
				}
				.cont {
					width: 0;
					flex-grow: 1;
				}
				.dropdown-toggle {
					@include breakpoint(max-md) {
						svg {
							max-height: 20px;
						}
					}
				}
				.post-author {
					display: flex;
					flex-grow: 1;
					width: 0;
					gap: 8px;
					color: #a7a7a7;
					font-size: 14px;
					.name {
						font-size: 18px;
						font-weight: 500;
						margin: 0;
					}
				}
			}
			&-body {
				letter-spacing: 0.36px;
				.subtitle {
					font-size: 18px;
					font-weight: 500;
					margin-bottom: 26px;
					letter-spacing: 0.36px;
				}
				.text {
					margin-bottom: 26px;
				}
				.post-img {
					border-radius: 14px;
					margin-bottom: 26px;
					max-width: 100%;
				}
				@include breakpoint(max-xl) {
					.text,
					.subtitle {
						font-size: 16px;
					}
				}
			}
		}
		.post-icons {
			display: flex;
			justify-content: space-between;
			gap: 16px;
			flex-wrap: wrap;
			button {
				display: flex;
				align-items: center;
				gap: 7px;
				color: var(--base);
				svg {
					width: 24px;
					height: 24px;
				}
			}
			@include breakpoint(max-sm) {
				gap: 8px;
				font-size: 14px;
				button {
					gap: 4px;
					svg {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
		select {
			option {
				background: #1b1609;
				color: var(--white);
			}
		}
		.activity-card {
			border-radius: 28px;
			border: 1px solid rgba(#ffc100, 0.1);
			background: linear-gradient(191deg, rgba(55, 43, 4, 0.04) -18.52%, rgba(255, 170, 0, 0.04) 52.54%);
			.__card {
				border-top: none;
				border-left: none;
				border-right: none;
			}
			.activities {
				padding: 45px 40px 36px;
				@include breakpoint(max-xl) {
					padding-inline: 25px;
				}
				.item {
					gap: 20px;
					display: flex;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					letter-spacing: 0.64px;
					padding-bottom: 50px;
					align-items: flex-start;
					position: relative;
					&::before {
						width: 1px;
						height: 100%;
						content: "";
						position: absolute;
						left: 29px;
						border-right: 1px dashed var(--white);
						z-index: -1;
					}
					&:last-child {
						&::before {
							display: none;
						}
						padding-bottom: 27px;
						border-bottom: 1px solid #887432;
					}
					.time {
						color: #000;
						padding: 5px 10px;
						background: var(--base);
						width: 59px;
						text-align: center;
					}
					.title {
						font-size: 20px;
						margin-bottom: 14px;
					}
				}
			}
		}
		.suggestions {
			display: flex;
			flex-direction: column;
			gap: 24px;
			.cont {
				width: 0;
				flex-grow: 1;
				align-items: center;
				.name {
					font-size: 18px;
					font-weight: 500;
					margin-bottom: 4px;
				}
				color: #a7a7a7;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.28px;
			}
			.img {
				width: 42px;
				img {
					width: 100%;
					aspect-ratio: 1;
					object-fit: cover;
					border-radius: 50%;
				}
			}
			button {
				color: #ffc100;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.36px;
				border-radius: 6px;
				background: #5a4008;
				padding: 10px;
				width: 98px;
			}
		}
		.trending-feeds {
			.item {
				gap: 14px;
				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
			.img {
				width: 86px;
				img {
					width: 100%;
					border-radius: 9px;
				}
			}
			.cont {
				width: 0;
				flex-grow: 1;
				.name {
					font-size: 24px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					letter-spacing: 0.96px;
					margin-bottom: 9px;
					// line limit 2
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				.info {
					color: #d3a926;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.64px;
					display: block;
				}
			}
			@include breakpoint(max-sm) {
				.cont {
					.name {
						font-size: 20px;
					}
				}
			}
			@include breakpoint(max-xxl) {
				@include breakpoint(xl) {
					.cont {
						.name {
							font-size: 20px;
						}
					}
				}
			}
		}
		.volumes-wrapper {
			padding: 25px 42px;
			background: linear-gradient(285deg, rgba(55, 43, 4, 0.4) -11.25%, rgba(254, 196, 79, 0.4) 110.63%);
			stroke-width: 1px;
			stroke: #ffc100;
			backdrop-filter: blur(10.5px);
			border-radius: 35px;
			@include breakpoint(max-lg) {
				padding: 25px 25px;
			}
			@include breakpoint(max-sm) {
				padding: 25px 20px;
			}
		}
		.volumes {
			display: flex;
			.item {
				border-radius: 26px;
				background: #33270c;
				.img {
					border-radius: 26px 26px 0px 0px;
					overflow: hidden;
					img {
						max-width: 100%;
					}
					position: relative;
					.logo {
						position: absolute;
						left: 15px;
						top: 15px;
					}
				}
				.title {
					font-size: 20px;
					margin-bottom: 10px;
					font-weight: 700;
					color: #fff;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;

					line-height: 24px;
				}
				.text {
					color: #e1e1e1;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 19px;
					// line limit 2
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin-bottom: 17px;
				}
				color: #b8b8b8;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 19px;
				.lesson,
				.duration {
					display: inline-flex;
					gap: 3px;
					align-items: center;
				}
				.cont {
					border-radius: 0 0 26px 26px;
					background: #33270c;
					padding: 16px 18px;
				}
			}
		}
		.education-wrapper {
			display: flex;
			flex-wrap: wrap;
			gap: 31px;
			border-radius: 40px;
			background: linear-gradient(180deg, #372b04 0%, #bc7f01 113.4%);
			padding: 35px 30px;
			.title {
				@include breakpoint(xl) {
					font-size: 26px;
					margin-bottom: 7px;
				}
			}
			.left {
				width: 58%;
				max-width: 568px;
				@include breakpoint(max-xxl) {
					flex-grow: 1;
				}
				iframe {
					border-radius: 29px;
					display: inline-flex;
					margin-bottom: 26px;
				}
				font-size: 16px;
			}
			.right {
				width: 200px;
				flex-grow: 1;
				color: #fff;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
			@include breakpoint(max-md) {
				padding: 24px;
				.left,
				.right {
					width: 100%;
				}
			}
			@include breakpoint(max-sm) {
				padding: 16px;
				iframe {
					height: 240px;
				}
			}
		}
		.video-iframe {
			border-radius: 29px;
			display: inline-flex;
			margin-bottom: 66px;
			@include breakpoint(max-xl) {
				height: 360px;
				margin-bottom: 26px;
			}
			@include breakpoint(max-md) {
				height: 260px;
				margin-bottom: 26px;
			}
		}
		.lesson-excercise {
			display: flex;
			flex-direction: column;
			gap: 12px;
			.item {
				display: flex;
				align-items: center;
				gap: 16px;
				width: 100%;
				background: transparent;
				outline: none;
				box-shadow: none;
				border: none;
				text-align: left;
				color: var(--white);
				padding: 6px 14px;
				.serial {
					width: 31px;
					height: 31px;
					background-size: contain !important;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.info {
					width: 0;
					flex-grow: 1;
					.title {
						font-size: 20px;
						margin-bottom: 4px;
						color: var(--white);
					}
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					.time {
						color: var(--base);
					}
				}
				&.active {
					border-radius: 9px;
					background: #fec44f;
					.title {
						color: #000;
					}
					color: #000;
					.serial {
						color: #fff;
						filter: invert(1);
					}
					--base: #ff1f00;
				}
			}
			@include breakpoint(max-xxl) {
				gap: 4px;
				.item {
					.info {
						.title {
							font-size: 16px;
						}
						font-size: 13px;
					}
				}
			}
		}
		.lesson-excercise-wrapper {
			max-height: 430px;
			overflow-y: auto;
		}
		.author-sidebar {
			border-radius: 42px;
			border: 1px solid #ffc100;
			background: linear-gradient(191deg, rgba(55, 43, 4, 0.4) -18.52%, rgba(255, 170, 0, 0.4) 52.54%);
			padding: 20px;
			.author-area {
				position: relative;
				.banner {
					border-radius: 42px;
					width: 100%;
					border: 1px solid #ffc100;
				}
				.author-img {
					border-radius: 129px;
					border: 4px solid #654605;
					position: relative;
					&::after {
						content: "";
						inset: 0;
						position: absolute;
						border-radius: 50%;
						background: linear-gradient(0deg, rgba(255, 193, 0, 0.3) 0%, rgba(255, 193, 0, 0.3) 100%);
					}
					width: 129px;
					img {
						width: 100%;
						aspect-ratio: 1;
						object-fit: cover;
					}
					margin: -65px auto 0;
				}
			}
		}
		.text-20 {
			font-size: 20px;
		}
		.education-row {
			@include breakpoint(xxl) {
				> .col-xl-8 {
					max-width: calc(100% - 458px);
					flex-grow: 1;
				}
				> .col-xl-4 {
					max-width: 458px;
				}
			}
		}
		.education-expand-row {
			@include breakpoint(xxl) {
				> .col-xl-8 {
					max-width: calc(100% - 500px);
					flex-grow: 1;
				}
				> .col-xl-4 {
					max-width: 500px;
				}
			}
		}
		.upcoming-classes {
			display: flex;
			flex-direction: column;
			gap: 17px;
			.item {
				display: flex;
				gap: 14px;
				align-items: center;
				img {
					width: 71px;
					height: 71px;
					aspect-ratio: 1;
					border-radius: 15px;
					object-fit: cover;
				}
				.cont {
					width: 0;
					flex-grow: 1;
					.title {
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						margin-bottom: 6px;
					}
					.tutor {
						color: #fec44f;
						font-size: 16px;
						display: block;
					}
				}
			}
		}
		.video-post-top {
			display: flex;
			flex-wrap: wrap;
			gap: 27px;
			align-items: center;
			justify-content: space-between;
			.post-author {
				position: relative;
				display: flex;
				gap: 18px;
				align-items: center;
				.cont {
					color: var(--base);
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					.name {
						margin-bottom: 8px;
					}
				}
				.img {
					position: relative;
					img {
						width: 82px;
					}
					.icon {
						position: absolute;
						bottom: 0;
						right: 0;
						svg {
							width: 26px;
							height: 26px;
						}
					}
				}
			}
		}
		.video-buttons {
			.base-btn {
				border-radius: 8px;
				background: linear-gradient(173deg, rgba(255, 193, 0, 0.4) -10.49%, rgba(255, 170, 0, 0.4) 74.76%);
				svg {
					width: 24px;
					height: 24px;
				}
				border: none;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				padding-inline: 17px;
			}
			.outline-btn {
				background: transparent;
				color: var(--base);
				font-size: 16px;
				font-style: normal;
				padding-inline: 17px;
				font-weight: 400;
			}
		}
		.video-post-content {
			margin-top: 24px;
			.content-left {
				flex-grow: 1;
				width: 0;
				max-width: 650px;
				.title {
					margin-bottom: 24px;
					@include breakpoint(md) {
						font-size: 40px;
						font-style: normal;
						font-weight: 500;
						line-height: 43px;
						margin-bottom: 34px;
					}
				}
				p {
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					margin-bottom: 24px;
					@include breakpoint(md) {
						margin-bottom: 40px;
					}
				}
			}
			@include breakpoint(max-md) {
				.content-left {
					width: 100%;
				}
				.view-info {
					padding-top: 0;
				}
			}
		}
		.view-info {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			gap: 20px;
			width: 100%;
			max-width: 230px;
			padding-top: 30px;
			li {
				display: flex;
				align-items: center;
				gap: 19px;
				color: #887432;

				font-size: 16px;
				.icon {
					width: 37px;
					text-align: center;
				}
			}
		}
		.upcoming-videos-card {
			border-radius: 42px;
			border: 1px solid #ffc100;
			background: linear-gradient(191deg, rgba(55, 43, 4, 0.4) -18.52%, rgba(255, 170, 0, 0.4) 52.54%);
			padding: 43px 27px 65px;
			margin-bottom: 66px;

			@include breakpoint(max-xxl) {
				@include breakpoint(lg) {
					padding: 30px 18px;
				}
			}
			@include breakpoint(max-sm) {
				padding: 30px 18px;
			}
		}
		.upcoming-videos {
			display: flex;
			flex-direction: column;
			gap: 33px;
			.upcoming-video {
				display: flex;
				gap: 24px;
				align-items: flex-start;
				.img {
					position: relative;
					overflow: hidden;
					border-radius: 20px;
					width: 142px;
					.play-icon {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
				.cont {
					width: 0;
					flex-grow: 1;
					.title {
						font-size: 24px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						letter-spacing: 0.96px;
						color: var(--white);
						margin-bottom: 7px;
						// line limit 2
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
					.tutor {
						color: #ad8919;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						letter-spacing: 0.64px;
						margin-bottom: 6px;
					}
					.views {
						color: #ad8919;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						letter-spacing: 0.64px;
					}
				}
			}
			@include breakpoint(max-xxl) {
				@include breakpoint(xl) {
					.upcoming-video {
						gap: 16px;
						.img {
							width: 100px;
						}
						.cont {
							.title {
								font-size: 18px;
							}
							.views {
								font-size: 13px;
							}
						}
					}
				}
			}
			@include breakpoint(max-sm) {
				.upcoming-video {
					gap: 16px;
					.img {
						width: 100px;
					}
					.cont {
						.title {
							font-size: 18px;
						}
						.views {
							font-size: 13px;
						}
					}
				}
			}
		}
		.tab-menu {
			padding-bottom: 21px;
			border-bottom: 1px solid #988138;
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: 0.96px;
			display: flex;
			flex-wrap: wrap;
			gap: 14px;
			li:not(:last-child) {
				border-right: 1px solid #a0a0a0;
				padding-right: 14px;
			}
		}
		.chatting-body {
			display: flex;
			flex-wrap: wrap;
			gap: 30px;
			padding-block: 34px;
			border-bottom: 1px solid #988138;
		}
		.chatting {
			display: flex;
			gap: 12px;
			width: 100%;
			align-items: flex-start;
			.img {
				position: relative;
				width: 37px;
				img {
					width: 100%;
				}
				.icon {
					position: absolute;
					right: -2px;
					bottom: -7px;
				}
			}
			.cont {
				width: 0;
				flex-grow: 1;
				max-width: 272px;
				.name {
					font-size: 20px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					letter-spacing: 0.8px;
					margin-bottom: 9px;
					margin-top: 8px;
					.time {
						color: #ffc100;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						letter-spacing: 0.64px;
					}
				}
				.message {
					font-size: 16px;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
					// letter-spacing: 0.64px;
				}
			}
			&.my-text {
				flex-direction: row-reverse;
				.cont {
					text-align: right;
				}
			}
		}
		.search--form {
			width: 100%;
			margin-top: 27px;
			.form-control {
				height: 56px;
				border: 1px solid var(--base);
				font-size: 16px;
				color: var(--base);
				padding-left: 52px;
				padding-right: 52px;
			}
			.left-icon {
				position: absolute;
				left: 14px;
				top: 14px;
			}
			button {
				position: absolute;
				right: 14px;
				top: 14px;
			}
		}
		.date-slider-item {
			color: #ad8919;
			text-align: center;
			text-transform: uppercase;
			width: 64px;
			padding: 10px;
			background: transparent;
			border: none;
			border: 1px solid none;
			outline: none;
			&.active {
				color: var(--base);
				border-radius: 8px;
				border: 1px solid #ffc10070;
				border-left: 1px solid #ffc10040;
				border-top: 1px solid #ffc10040;
				background: #ffc1003d;
				border-radius: 15px;
				display: inline-block;
			}
		}
		.chatting-page-wrapper {
			display: flex;
			flex-wrap: wrap;
			gap: 40px;
			.chatting-page-left-sidebar {
				width: 30%;
				max-width: 375px;
				padding: 36px 13px;
				border-radius: 28px;
				border: 1px solid #ffbf0069;
				border-top: none;
				background: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
				.search-form {
					width: 100%;
					margin-bottom: 19px;
					.form-control {
						height: 71px;
						color: var(--white);
						border-color: var(--base);
						padding-inline: 20px;
						border-radius: 13px;

						&::placeholder {
							color: var(--white);
						}
					}
				}
				.subtitle {
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					letter-spacing: 0.32px;
				}
				@include breakpoint(max-xl) {
					width: 36%;
				}
				@include breakpoint(max-md) {
					width: 100%;
					max-width: 100%;
					max-height: 500px;
					overflow-y: auto;
				}
			}
			.chatting-page-right-sidebar {
				width: 30%;
				max-width: 462px;
				border-radius: 28px;
				border: 1px solid #ffbf0069;
				border-top: none;
				background: linear-gradient(180deg, rgba(55, 43, 4, 0.31) -38.07%, rgba(255, 170, 0, 0.31) 88.45%);
				padding: 25px 23px;
				.banner {
					border-radius: 13px;
				}
				.author-img {
					padding: 3px;
					border: 2px dashed #ffc100;
					border-radius: 50%;
					max-width: 118px;
					margin: -58px auto 16px;
					position: relative;
					img {
						width: 100%;
						border-radius: 50%;
						aspect-ratio: 1;
						object-fit: cover;
						object-position: center center;
					}
				}
				.author-name {
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 0.32px;
				}
				.txt {
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					max-width: 352px;
					margin-inline: auto;
				}
				.subtitle {
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.32px;
					margin-bottom: 23px;
				}
				@include breakpoint(max-xl) {
					width: 100%;
					// max-width: 100%;
					margin-inline: auto;
				}
			}
		}
		.mb-10 {
			margin-bottom: 10px;
		}
		.copy-link-input {
			.form-control {
				border-radius: 7px;
				background: #7b5b04;
				color: #ffb459;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				box-shadow: none;
				outline: none;
				border: none;
				height: 48px;
			}
			.btn {
				height: 40px;
				border-radius: 7px;
				background: rgba(255, 193, 0, 0.2);
				width: 109px;
				color: #ffc100;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				padding: 0;
				display: flex;
				align-items: center;
				gap: 3px;
				justify-content: center;
				position: absolute;
				right: 6px;
				top: 4px;
				&:active {
					border-color: var(--base);
				}
			}
			position: relative;
		}
		.mb-55 {
			margin-bottom: 55px;
		}
		.photos-gallery {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			.photos-gallery-item {
				width: calc((100% / 3) - (20px / 3));
				position: relative;
				img {
					width: 100%;
					border-radius: 14px;
					cursor: pointer;
				}
				.view-all {
					background: rgba(#ffc100, 0.61);
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 14px;
					cursor: pointer;
					height: 100%;
					width: 100%;
				}
			}
			padding-bottom: 30px;
			border-bottom: 2px solid var(--base);
		}
		.fs-14 {
			font-size: 14px;
		}
		.h-41 {
			height: 41px;
		}
		.user-list {
			&-item {
				&:not(:last-child) {
					margin-bottom: 46px;
				}
				.subtitle {
					font-size: 14px;
					font-weight: 600;
					color: var(--base);
					display: flex;
					align-items: center;
					gap: 9px;
					justify-content: space-between;
					&::after {
						content: "";
						width: 0;
						flex-grow: 1;
						height: 1px;
						background: #ffc100;
						max-width: 274px;
					}
				}
				.user-list-item-cont {
					display: flex;
					flex-direction: column;
					gap: 21px;
					font-size: 14px;
					font-weight: 600;
					.user-list-item-cont-item {
						display: flex;
						align-items: center;
						gap: 11px;
						color: var(--white);
						img {
							border-radius: 50%;
							aspect-ratio: 1;
							object-fit: cover;
							border: 2px solid var(--base);
							width: 34px;
						}
						&:hover {
							color: var(--base);
						}
					}
				}
				&:first-child {
					.user-list-item-cont {
						.user-list-item-cont-item {
							color: var(--base);
						}
					}
				}
			}
		}
		.search-hints {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			.search-hints-item {
				text-align: center;
				color: var(--white);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.28px;
				.img {
					padding: 5px;
					width: 50px;
					border-radius: 50%;
					border: 1px solid var(--base);
					margin: 0 auto 14px;
					img {
						width: 100%;
						aspect-ratio: 1;
						object-fit: cover;
						border-radius: 50%;
					}
				}
				&:first-child {
					.img {
						border-style: dashed;
					}
				}
			}
		}
		.chat-list-item {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 10px 11px;
			border-radius: 8px;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.28px;
			&.active {
				background: #60440a;
				padding-left: 21px;
			}
			.img {
				position: relative;
				.new-msg-count {
					border: 1px solid #473205;
					background: var(--base);
					width: 21px;
					height: 21px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					border-radius: 50%;
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}
			color: var(--white);
			.cont {
				width: 0;
				flex-grow: 1;
				.message {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 12px;
				}
			}
		}
		.last-msg {
			// line limit 1
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}
		.chat-wrapper {
			width: 280px;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			.chat-header {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				gap: 16px;
				border-radius: 24px 20px 0px 0px;
				background: #60440a;
				box-shadow: 0px 49px 18px 7px rgba(255, 193, 0, 0.05);
				padding: 16px 45px;
				color: var(--base);
				font-size: 16px;
				.img {
					width: 54px;
					aspect-ratio: 1;
					object-fit: cover;
					border: 1px solid var(--base);
					border-radius: 50%;
				}
				.name {
					font-size: 22px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 0.44px;
					margin-bottom: 3px;
				}
				@media screen and (max-width: 1650px) {
					padding: 16px;
					.name {
						font-size: 18px;
					}
				}
			}
			.chat-body {
				flex-grow: 1;
				height: 400px;
				padding: 48px;
				overflow-y: auto;
				// hide scrollbar
				&::-webkit-scrollbar {
					width: 0px;
					background: transparent; /* make scrollbar transparent */
				}
				@media screen and (max-width: 1650px) {
					padding-inline: 16px;
				}
				@media screen and (max-width: 500px) {
					padding-inline: 0;
				}
			}
		}
		.gap-12px {
			gap: 12px;
		}
		.chat-form {
			.post-form {
				border-radius: 10px;
				background: #392905;
				.form-control {
					background: transparent !important;
					border: none;
					height: 100px;
					min-height: 100px;
					padding-bottom: 0;
					@media screen and (max-width: 500px) {
						font-size: 12px;
					}
				}
				.send-form-bottom {
					position: relative;
					@media screen and (max-width: 500px) {
						svg {
							transform: scale(0.7);
						}
					}
				}
			}
		}
		.chat-items {
			display: flex;
			flex-direction: column;
			gap: 30px;
			@include breakpoint(xxl) {
				gap: 48px;
			}
			.chat-item {
				width: 90%;
				max-width: 511px;
				display: flex;
				gap: 11px;
				&.my-text {
					margin-inline-start: auto;
					flex-direction: row-reverse;
					text-align: right;
				}
				.img {
					width: 42px;
					img {
						width: 100%;
						aspect-ratio: 1;
						object-fit: cover;
						border-radius: 50%;
					}
				}
				.cont {
					width: 0;
					flex-grow: 1;
					.name {
						font-size: 16px;
						margin-bottom: 14px;
						.time {
							color: #949494;
							font-size: 14px;
						}
					}
					.message {
						background: #392905;
						padding: 10px 14px;
						border-radius: 10px;
						font-size: 14px;
						display: inline-block;
						margin-bottom: 21px;
					}
					@media screen and (max-width: 500px) {
						.name {
							font-size: 14px;
						}
						.message {
							font-size: 12px;
						}
					}
				}
			}
		}
		.today {
			color: #ffffff;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.28px;
			display: flex;
			align-items: center;
			gap: 10px;
			&::before,
			&::after {
				content: "";
				flex-grow: 1;
				height: 1px;
				background: #ffc100;
			}
		}

		.setting-wrapper {
			.box-groups {
				display: grid;
				grid-template-columns: 396px 1fr;
				gap: 30px;
				@media all and (max-width: 1300px) {
					grid-template-columns: repeat(2, 1fr);
				}
				@media all and (max-width: 991px) {
					grid-template-columns: repeat(1, 1fr);
				}
			}
			.box-item {
				border-radius: 28px;
				border: 1px solid #ffc100;
				background: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
				&:nth-child(1) {
					padding: 15px 13px 17px;
				}
				&:nth-child(2) {
					padding: 27px 30px 50px;
					@media all and (max-width: 525px) {
						padding: 27px 10px 50px;
					}
				}
			}
			.left-side {
				padding: 15px 13px 17px;
			}
			.img-contents {
				position: relative;
				margin-bottom: 91px;
				.cover-photo {
					width: 100%;
					border-radius: 22px;
				}
			}

			.change-cover-btn {
				position: absolute;
				top: 16px;
				right: 8px;
				background-color: transparent;
				color: #ffc100;
				font-family: Fira Sans;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				padding: 5px 9px;
				align-items: center;
				gap: 4px;
				border-radius: 5px;
				border: 1px solid #ffc100;
			}
			.profile-img-content {
				position: absolute;
				max-width: 140px;
				max-height: 140px;
				bottom: -70px;
				left: 50%;
				transform: translate(-50%);
			}
			.dil-circle {
				position: relative;
			}
			.profile-img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: 23px;
				border-radius: 100%;
			}
			.change-profile-photo-btn {
				position: absolute;
				right: 23px;
				bottom: 23px;
				background-color: transparent;
				border: none;
				width: 30px;
				height: 30px;
				background-color: #ffc100;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			h4 {
				color: #fff;
				font-family: Fira Sans;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				letter-spacing: 1px;
				text-align: center;
				margin: 0;
				margin-bottom: 15px;
			}
			h5 {
				color: #ffc100;
				font-family: Fira Sans;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.7px;
				text-align: center;
				margin: 0;
				margin-bottom: 32px;
			}
			h6 {
				color: #fff;
				font-family: Fira Sans;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin: 0;
				@media all and (max-width: 1050px) {
					font-size: 14px;
				}
				@media all and (max-width: 375px) {
					font-size: 12px;
				}
			}
			span {
				color: #fff;
				font-family: Fira Sans;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@media all and (max-width: 1050px) {
					font-size: 12px;
				}
				@media all and (max-width: 375px) {
					font-size: 10px;
				}
			}
			.number-contents {
				display: flex;
				justify-content: space-around;
				gap: 5px;
				.number-item {
					background-image: url("../public/img/setting/circle-conc.png");
					background-size: 100%;
					background-repeat: no-repeat;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					width: 97px;
					height: 97px;
					@media all and (max-width: 1050px) {
						width: 80px;
						height: 80px;
					}
					@media all and (max-width: 375px) {
						width: 77px;
						height: 77px;
					}
				}
			}

			.description {
				border-radius: 28px;
				border: 1px solid #ffc100;
				padding: 10px 22px 23px 22px;
				max-width: 407px;
				margin: 0 auto;
				margin-top: 47px;
				@media all and (max-width: 991px) {
					max-width: 100%;
				}

				h3 {
					color: #ffc100;
					font-family: Open Sans;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					border-bottom: 0.2px dashed rgba(255, 191, 0, 0.218);
					padding-bottom: 5px;
					margin-bottom: 11px;
				}
				p {
					color: #fff;
					font-family: Open Sans;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin: 0;
				}
			}

			.account-removal {
				max-width: 407px;
				margin: 0 auto;
				border-radius: 11px;
				background: #865b07;
				margin-top: 11px;
				padding: 22px 22px 21px 11px;
				@media all and (max-width: 991px) {
					max-width: 100%;
				}
				h3 {
					color: #fff;
					font-family: Fira Sans;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 113%;
					letter-spacing: 0.8px;
				}
				p {
					color: #ffc100;
					font-family: Fira Sans;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.6px;
					margin-bottom: 23px;
				}

				.btn-groups {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					gap: 16px;
					@media all and (max-width: 425px) {
						gap: 8px;
					}
				}
				button {
					background-color: transparent;
					color: #ffc100;
					font-family: Open Sans;
					font-size: 15px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					padding: 13px;
					border-radius: 5px;
					border: none;
					@media all and (max-width: 425px) {
						padding: 8px 5px;
					}
					&:nth-child(1) {
						background: rgba(255, 180, 89, 0.3);
					}
					&:nth-child(2) {
						color: white;
						border: 1px solid #ffc100;
					}
				}
			}

			.right-side {
				h2 {
					color: #fff;
					font-family: Fira Sans;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					line-height: 113%;
					letter-spacing: 1.2px;
				}
				h6 {
					color: #ffc100;
					font-family: Fira Sans;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 113%; /* 15.82px */
					letter-spacing: 0.7px;
					margin: 0;
				}

				input {
					color: #fff;
					font-family: Fira Sans;
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: 113%;
					background-color: transparent;
					border: none;
					outline: none;
					margin-top: 11px;
					display: block;
					width: 300px;
					@media all and (max-width: 1140px) {
						width: 100%;
					}

					@media all and (max-width: 525px) {
						width: 220px;
						font-size: 15px;
					}
					@media all and (max-width: 350px) {
						width: 100%;
					}
				}

				.input-groups {
					margin-top: 38px;
					display: flex;
					flex-direction: column;
					gap: 36px;
				}
				.input-layout {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 20px;
				}
				.edit-btn {
					border-radius: 5px;
					background: #a67e00;
					color: #fff;
					font-family: Open Sans;
					font-size: 15px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					width: 113px;
					height: 45px;
					border: none !important;
					@media all and (max-width: 525px) {
						width: 80px;
						height: 36px;
					}
				}

				.two-step-verification {
					border-radius: 11px;
					background: #865b07;
					padding: 15px 22px 40px;
					margin-top: 27px;
					input {
						border-radius: 6px;
						border: 1px solid #ffc100;
						width: 100% !important;
						color: #ffc100;
						font-family: Fira Sans;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 113%;
						letter-spacing: 0.7px;
						padding: 14px 17px;
						&::placeholder {
							color: #ffc100;
						}
					}

					.btn-groups {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						margin-top: 35px;
						gap: 10px;
						button {
							color: #fff;
							font-family: Fira Sans;
							font-size: 20px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							letter-spacing: 1px;
							border-radius: 6px;
							border-radius: 6px;
							padding: 10px 20px;
							@media all and (max-width: 350px) {
								padding: 8px 10px;
							}
							&:nth-child(1) {
								background: #ffc100;
								border: 1px solid #ffb459;
							}
							&:nth-child(2) {
								border: 1px solid #ffc100;
								background-color: transparent;
							}
						}
					}
					h3 {
						color: #ffc100;
						font-family: Fira Sans;
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						line-height: 113%;
					}
					p {
						color: #ffc100;
						font-family: Fira Sans;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 113%;
						letter-spacing: 0.7px;
					}
				}

				.toggole {
					width: 48px;
					height: 24px;
					background-color: #ffc100;
					border-radius: 18px;
					display: flex;
					align-items: center;
					padding: 2px;
					cursor: pointer;
					transition: all 0.3s ease-in-out;
					@media all and (max-width: 575px) {
						width: 42px;
						height: 20px;
						padding: 1px 2px;
					}
				}

				.white-circle {
					width: 20px;
					height: 20px;
					background-color: white;
					border-radius: 100%;
					transition: all 0.3s ease-in-out;
					@media all and (max-width: 575px) {
						width: 15px;
						height: 15px;
					}
				}
			}

			.right-side-profile-circle {
				position: relative;
				width: 97px;
				height: 97px;
				.profile-img {
					padding: 20px;
				}

				.add-profile-btn {
					position: absolute;
					left: 0px;
					bottom: 18px;
					background-color: transparent;
					border: none;
					width: 26px;
					height: 26px;
					background-color: #ffc100;
					border-radius: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		.profile-wrapper {
			border-radius: 28px;
			border: 1px solid #ffc100;
			background: linear-gradient(
				180deg,
				rgba(55, 43, 4, 0.4) -38.07%,
				rgba(255, 170, 0, 0.4) 88.45%
			);
			.cover {
				width: calc(100% + 2px);
				height: 360px;
				object-fit: cover;
				border-radius: 28px;
				margin-left: -1px;
				margin-right: -1px;
				margin-top: -2px;
			}
			.main-content {
				padding-bottom: 120px;
				max-width: 1310px;
				padding-inline: 15px;
				margin: 0 auto;
				.profile-top-content {
					padding: 28px 42px;
					border-radius: 25px;
					background: rgba(96, 68, 10, 0.78);
					backdrop-filter: blur(2px);
					display: flex;
					flex-wrap: wrap;
					margin-top: -94px;
					position: relative;
					z-index: 1;
					.left-side {
						display: flex;
						align-items: center;
						gap: 24px;
						width: 45%;
						padding-right: 16px;
						.img {
							width: 140px;
							height: 140px;
							display: flex;
							align-items: center;
							justify-content: center;
							img {
								width: 95px;
								aspect-ratio: 1;
								object-fit: cover;
								border-radius: 50%;
							}
						}
						.cont {
							width: 0;
							flex-grow: 1;
							max-width: 275px;
							.name {
								font-size: 20px;
								font-style: normal;
								font-weight: 700;
								line-height: normal;
								letter-spacing: 1px;
							}
							.badge-success {
								border-radius: 21px;
								background: rgba(13, 163, 0, 0.4);
								color: #14ff00;
								font-size: 13px;
								font-style: normal;
								font-weight: 400;
							}
							.designation {
								display: block;
								color: #ffc100;
								font-size: 14px;
								letter-spacing: 0.7px;
							}
							.text {
								font-size: 12px;
								font-style: normal;
								font-weight: 400;
							}
						}
					}
					.count-area {
						flex-grow: 1;
						.seperator {
							width: 1px;
							height: 95px;
							background: var(--base);
						}
					}
					@include breakpoint(max-xl) {
						padding-inline: 24px;
					}
					@include breakpoint(max-xl) {
						gap: 32px;
						.count-area,
						.left-side {
							width: 100%;
							max-width: 100%;
							.cont {
								max-width: 100%;
							}
							.seperator:first-child {
								display: none;
							}
						}
					}
					@include breakpoint(max-sm) {
						gap: 16px;
						padding-inline: 16px;
						.left-side {
							.img {
								width: 80px;
								height: 80px;
								img {
									width: 60px;
								}
							}
							@media screen and (max-width: 424px) {
								flex-wrap: wrap;
								.cont {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
		.count-area {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 16px;
			justify-content: space-between;
			.count-item {
				width: 120px;
				height: 120px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.count {
					font-weight: 600;
					font-size: 16px;
				}
				.subtext {
					font-size: 14px;
				}
			}
			@include breakpoint(max-sm) {
				@media screen and (max-width: 424px) {
					gap: 12px;
					.seperator {
						display: none;
					}
					.count-item {
						.count {
							font-size: 12px;
						}
						.subtext {
							font-size: 10px;
						}
					}
				}
			}
		}
		.max-w-137 {
			max-width: 137px;
		}
		.calendar-item {
			border-radius: 22px;
			background: #60440a;
			display: flex;
			&:not(:last-child) {
				margin-bottom: 30px;
			}
			border: 1px solid transparent;
			transition: all ease 0.3s;
			.cont {
				align-self: center;
				padding: 16px 29px;
				width: 0;
				flex-grow: 1;
				border-right: none;
				@include breakpoint(max-sm) {
					padding: 16px;
				}
			}
			.img {
				width: 191px;
				position: relative;
				margin-right: -1px;
				margin-top: -1px;
				.logo-img {
					position: absolute;
					left: 15px;
					top: 15px;
					z-index: 1;
				}
				.main-img {
					height: calc(100% + 2px);
					object-fit: cover;
					width: 100%;
					border-radius: 0 22px 22px 0;
				}
				@media screen and (max-width: 424px) {
					width: 100px;
				}
			}
			h6 {
				margin-bottom: 9px;
			}
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: -0.26px;
			a {
				color: #ffc100;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.75px;
			}
			&:hover {
				border-color: var(--base);
			}
		}
		.text-13 {
			font-size: 13px;
		}
		.calendar-2 {
			border-radius: 22px;
			background: #7e590b;
			padding: 25px 37px;
			.react-datepicker__day {
				border-radius: 2px;
				background: #674e04;
				margin: 1px !important;
				aspect-ratio: 1;
				max-width: 58px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.react-datepicker__header .react-datepicker__day-names {
				.react-datepicker__day-name {
					color: #88869f !important;
				}
			}
			// .inline-date .react-datepicker .react-datepicker__day--selected {
			// 	border: 1px solid var(--white) !important;
			// 	background: #674e04 !important;
			// }
			.react-datepicker__day-names {
				padding-top: 0 !important;
			}
			@include breakpoint(md) {
				@include breakpoint(max-xl) {
					padding: 25px 20px;
				}
			}
			.react-datepicker__week {
				.react-datepicker__day--in-range,
				.react-datepicker__day:first-child {
					background: var(--base) !important;
					color: var(--white) !important;
				}
			}
			.inline-date .react-datepicker .react-datepicker__navigation {
				border-radius: 2px !important;
				background: #674e04 !important;
				border: none !important;
				width: 36px;
				height: 36px;
			}
			.react-datepicker__navigation--previous {
				right: 48px;
				left: unset;
			}
			.react-datepicker__current-month {
				text-align: left;
				padding-left: 16px;
			}
			.react-datepicker__current-month {
				margin-bottom: 10px;
			}
			.react-datepicker__day--highlighted-custom-1 {
				position: relative;
				&::before {
					content: "";
					position: absolute;
					top: 7px;
					right: 7px;
					width: 7px;
					height: 7px;
					border-radius: 50%;
					background: #fff;
				}
			}
			.react-datepicker__day--highlighted-custom-2 {
				position: relative;
				&::before {
					content: "";
					position: absolute;
					top: 7px;
					right: 7px;
					width: 7px;
					height: 7px;
					border-radius: 50%;
					background: #37ff7b;
				}
			}
		}
		.profile-project-card {
			border-radius: 20px;
			background: linear-gradient(143deg, #885c00 -3.23%, #78540a 105.8%);
			padding: 23px 31px;
			@include breakpoint(max-sm) {
				padding: 16px;
			}
		}
		.trending-feeds-2 {
			.item:not(:last-child) {
				border-bottom: 1px solid #988138;
				margin-bottom: 18px !important;
				padding-bottom: 18px;
			}
		}
		.all-projects {
			.item {
				gap: 16px;
				border-radius: 20px;
				background: #94680e;
				padding: 14px;
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
			.img {
				width: 80px;
				img {
					width: 100%;
					border-radius: 9px;
				}
			}
			.cont {
				width: 0;
				flex-grow: 1;
				.name {
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 100%; /* 16px */
					letter-spacing: -0.32px;
					// line limit 2
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				.info {
					color: #d3a926;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					letter-spacing: -0.28px;
				}
			}
		}
		.text-14 {
			font-size: 14px;
		}

		.menu-item {
			.active{
				path{
						stroke:#fff !important;
					}
			}
		}
		.menu-item{
			position: relative;
			&::after{
				content: "";
				position: absolute;
				right: -35px;
				height: 100%;
				width: 4px;
				background-color: rgba(255, 199, 70, 1);
				transition: 0.4s;
				transition-delay: 0.3s;
				opacity: 0;
				animation: slideDown 0.7s ease-out;
			}
			@keyframes slideDown {
				from {
				  transform: translateY(-100%);
				}
				to {
				  transform: translateY(0);
				}
			  }

		}
		.sidebar-menu{
			.active::after{
				opacity: 1;
			}
			.active{
				path{
					stroke: #fff !important;
				}
			}
		}
		.dark-light-mode{
			border:1px solid #FFC100;
			border-radius: 6px;
			padding: 4px;
			max-height: 42px;
			button{
				height: 32px;
				width: 50px;
				display: flex;
				align-items: center;
				border:none;
			}

			.active{
				background: linear-gradient(89.67deg, #FEC44F 0.32%, rgba(255, 193, 0, 0) 133.2%);
				stroke: #fff !important;
				.darkModeIcon{
					path{
						fill:none;
					}
				}

			}
		}

		// @media only screen and (max-width: 600px)
		@media only screen and (max-width: 3840px){
			.auth-layout .auth-right {
				left: -44px;
			}
			.page_content{
				// max-height: 90vh !important;
			}
		}
		@media only screen and (max-width: 2880px){
			.auth-layout .auth-right {
				left: -34px;
			}
			.page_content{
				// max-height: 90vh !important;
			}
		}
		@media only screen and (max-width: 1920px){
			.auth-layout .auth-right {
				left: -20px;
			}
			.page_content{
				// max-height: 82vh !important;
			}
		}
		@media only screen and (max-width: 1450px){
			.auth-layout .auth-right {
				left: -19px;
			}
			.page_content{
				// max-height: 82vh !important;
			}
		}
		@media only screen and (max-width: 1290px){
			.auth-layout .auth-right{
				left: -14px;
			}
			.page_content{
				// max-height: 82vh !important;
			}
		}

	}

.light {
	.educator-slider{
		.course-items .body-content {
			background-color: rgba(51, 39, 12, 1);
			border-radius: 0 0 26px 26px;
			.course-title{
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
				letter-spacing: 0em;
				text-align: left;
				color:#fff;
			}
			.course-details{
				font-size: 14px;
				font-weight: 400;
				line-height: 19px;
				letter-spacing: 0em;
				text-align: left;
				color:	rgba(225, 225, 225, 1);
			}
			p{
				color: #000000;
			}
			.profile-btn{
				padding: 8px 18px;
				border-radius: 6px;
				background:rgba(255, 193, 0, 1);
				font-size: 14px;
				font-weight: 400;
				line-height: 19px;
				letter-spacing: 0em;
				text-align: center;
				color:rgba(255, 241, 241, 1) !important;
			}
			.subscribe-btn{
				margin-left: 5px;
				padding: 8px 18px;
				border-radius: 6px;
				background:transparent;
				font-size: 14px;
				font-weight: 400;
				line-height: 19px;
				letter-spacing: 0em;
				text-align: center;
				color: rgba(255, 193, 0, 1)!important;
				border: 1px solid rgba(255, 193, 0, 1) !important;
			}
		}

		 .course-items .body-content .course-time p{
			color: #000000;
		}
	}


	background-color: #F5F8FF;
	.light-logo{
		display: block !important;
	}
	.dark-logo{
		display: none !important;
	}
	.auth-layout {
		background-image: url("/light/lightBg.png") !important;
		.auth-left {
			height: 100vh;
			min-height: 660px;
			position: relative;
			overflow: hidden;
			padding: 47px 82px;
			position: sticky;
			top: 0;

			.logo {
				display: inline-block;
				position: relative;
			}

			.main-img {
				position: absolute;
				top: 50%;
				left: 55%;
				transform: translate(-50%, -50%);
				max-width: 100%;
			}

			.step-items {
				position: absolute;
				left: 45%;
				width: 100%;
				transform: translateX(-50%);
				bottom: 60px;
				max-width: 400px;
			}
		}

		.auth-right {
			width: 95% !important;
			background: #fff;
			border-radius: 42px;
			border: 1px solid #ffbf0041;
			position: relative;
			padding: 35px 50px;
			z-index: 1;
			.submit-btn{
				background-color: #FFC100;
				border: 0;
			}
			.eye{
				path{
					stroke: #000;
				}
			}
			.auth-header {
				margin-bottom: 36px;

				@include breakpoint(max-xl) {
					@include breakpoint(lg) {
						.title {
							font-size: 2rem;
						}
					}
				}
				.title{
					color: #000000;
				}
				p{
					color: #000000;
				}

			}

			@media screen and (max-width: 1500px) {
				padding: 37px 32px 37px 43px;
				margin: 37px 44px 37px 0px;
				width: calc(50% - 10px);
			}
		}

		@include breakpoint(max-lg) {
			flex-wrap: wrap;
			background-position: left center !important;
			padding-bottom: 24px;

			.auth-left,
			.auth-right {
				width: 100%;
				margin: 0;
			}

			.auth-left {
				min-height: 0;
				height: 750px;
				position: relative;

				.main-img {
					max-width: 700px;

					@include breakpoint(max-sm) {
						max-width: 600px;
					}

					@media screen and (max-width: 575px) {
						left: 64%;
					}

					@media screen and (max-width: 500px) {
						left: 62%;
					}

					@media screen and (max-width: 520px) {
						left: 68%;
					}

					@media screen and (max-width: 375px) {
						left: 70%;
					}

					@media screen and (max-width: 320px) {
						left: 76%;
					}
				}

				@include breakpoint(max-sm) {
					.step-items {
						left: 0;
						transform: translate(0);
						width: 100%;
						justify-content: center;
						max-width: 100%;
						padding-inline: 24px;

						img {
							max-width: 100%;
						}
					}
				}
			}

			.auth-right {
				margin-inline: 24px;
				padding-inline: 24px;
			}
		}
	}

	.form--label {
		color: #000000;
		display: block;
		margin-bottom: 5px;
	}

	.form--control {
		height: 50px;
		border-radius: 5px;
		border: 1px solid #000000;
		background-color: transparent;
		box-shadow: none !important;
		outline: none;
		color: #000000 !important;
		padding: 13px;

		&::placeholder {
			color: #988138;
		}

		&:focus {
			background-color: transparent;
			border: 1px solid #000000;
		}

		@include breakpoint(max-xl) {
			@include breakpoint(lg) {
				height: 50px;
			}
		}
	}

	.eye {
		position: absolute;
		right: 0;
		top: 0;
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		z-index: 9;

		@include breakpoint(max-xl) {
			@include breakpoint(lg) {
				height: 50px;
				width: 50px;
			}
		}
	}

	.form--check {
		cursor: pointer;

		.uncheck-icon {
			width: 22px;
			height: 22px;
			display: inline-block;
			border: 2px solid rgba(#ffffff, 0.6);
			top: 50%;
			left: 0;
			position: absolute;
			transform: translateY(-38%);
		}

		padding: 0;

		.icon {
			width: 28px;
			position: relative;
		}

		display: flex;
		align-items: center;
		gap: 5px;
		margin: 0;
	}

	.submit-btn {
		border-radius: 5px;
		border: 1px solid #000000;
		background: rgba(255, 193, 0, 0.2);
		width: 100%;
		color: var(--white);
		height: 50px;
	}

	.text-underline {
		text-decoration: underline;
	}

	.text-base {
		color: #000000;
	}

	.plan--row {
		@include breakpoint(xxl) {
			--bs-gutter-x: 35px;
			--bs-gutter-y: 35px;
		}
	}

	.plan-item {
		background: #000000;
		border-radius: 40px;
		padding: 24px 27px 40px;

		.plan-title {
			font-size: 30px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 22px;
			color: #000;

			.name {
				color: #000;
			}

			.badge {
				padding: 6px 10px;
				font-size: 18px;
				font-weight: 400;
				color: #000;
				background: var(--white);
			}
		}

		.plan-price {
			padding-bottom: 16px;
			border-bottom: 1px solid #2e2e2e;
			margin-bottom: 44px;

			.price {
				display: flex;
				align-items: center;
				gap: 2px;
				font-size: 24px;
				font-weight: 700;
				color: #000;

				.month {
					font-size: 10px;
					font-weight: 400;
				}

				.currency {
					font-size: 16px;
					font-weight: 400;
				}
			}
		}

		.plan-info {
			margin-bottom: 48px;

			li {
				display: flex;
				align-items: center;
				gap: 11px;

				&:not(:last-child) {
					margin-bottom: 21px;
				}

				color: #000000;
				font-size: 16px;
				font-weight: 400;

				strong {
					font-weight: 500;
				}

				svg {
					width: 18px;
					height: 18px;
				}

				span {
					width: 0;
					flex-grow: 1;
				}
			}
		}

		.plan-btn {
			border-radius: 11px;
			border: 1px solid #000;
			background: #fff;
			width: 162px;
			min-height: 44px;
			padding: 14px 10px;
			text-align: center;
			font-weight: 500;
			font-size: 13px;
			color: #000;
			transition: all ease 0.3s;

			&:hover {
				background: #000000;
				// border-color: var(--white);
				// color: var(--white);
			}
		}

		@include breakpoint(max-sm) {
			padding: 24px 15px 30px;

			.plan-info {
				font-size: 14px;
				margin-bottom: 24px;
			}
		}
	}

	.text--label-white {
		color: var(--white);

		.form-label,
		.form--control {
			color: var(--white) !important;
		}
	}

	.verify-button {
		color: #fff;
		text-align: center;
		font-family: Fira Sans;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		border-radius: 11px;
		background: linear-gradient(95deg, #f5bd00 -0.91%, #995400 99.33%);
		height: 68px;
		padding-inline: 38px;
		border: none;
		outline: none;
		box-shadow: none;

		@include breakpoint(max-sm) {
			font-size: 16px;
			height: 50px;
			padding-inline: 24px;
		}
	}

	.dashboard-layout {
		display: flex;
		min-height: 100dvh;

		.dashboard-sidebar {
			background: #fff;
			width: 258px;
			height: 100dvh;
			position: sticky;
			top: 0;

			hr {
				display: none !important;
			}

			.logo {
				padding: 18px 25px;

				a {
					display: block;

					img {
						width: 100%;
					}
				}
			}

			display: flex;
			flex-direction: column;
			transition: all ease 0.3s;
			z-index: 999;

			@include breakpoint(lg) {
				&:not(.active) {
					width: 86px;

					.aside-bottom {
						display: none;
					}

					.sidebar-menu {
						a {
							padding-inline: 0;
							justify-content: center;

							.name {
								display: none;
							}
						}
					}
				}
			}

			@include breakpoint(max-lg) {
				position: fixed;
				top: 0;
				left: 0;

				&.active {
					visibility: hidden;
					transform: translateX(-100%);
				}
			}
		}

		.dashboard-content {
			flex-grow: 1;
			width: 200px;
			position: relative;
			z-index: 1;
			padding: 0 28px 42px;
			padding-bottom: 0 !important;
			overflow: hidden;

			@include breakpoint(max-lg) {
				width: 100%;
				padding: 0 14px 42px;
			}
		}
	}

	.dark-light-mode {
		background-color: #FFFFFF;

		.active {
			svg {
				stroke: #fff !important;
			}
		}

		.btn {
			svg {
				stroke: #000000;
				fill: #000000 !important;
			}
		}
	}

	.sidebar-menu {
		margin-top: 41px;
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		gap: 20px;

		margin-inline: auto;
		max-width: 224px;
		width: 100%;

		a {
			display: flex;
			align-items: center;
			padding: 13px 16px;
			gap: 10px;
			color: #000000;
			font-size: 16px;

			&.active {
				color: var(--white);
				border-radius: 7px;
				border: 1px solid #fec44f;
				background: #FEC44F;
				box-shadow: 0px 6px 19px 0px #FFC10054;

			}
		}
	}

	.toggle-button {
		position: fixed;
		bottom: 0;
		left: 0;
		background: transparent;
		width: 45px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		box-shadow: none;
		border-radius: 3px 1px 1px 3px;
		transform: rotate(0deg);
		z-index: 999;
		left: 85px;
		overflow: hidden;

		&.active {
			left: 258px;
			border-radius: 3px 1px 1px 3px;
			transform: rotate(180deg);
		}

		transition: all ease 0.3s;

		@include breakpoint(max-lg) {
			transform: rotate(180deg);
			left: 258px;

			&.active {
				left: 0;
				transform: rotate(0deg);
			}
		}

		&::after,
		&::before {
			position: absolute;
			content: "";
			inset: 0;
			background: var(--body);
			z-index: -1;
		}

		&::after {
			background: linear-gradient(41deg, rgba(55, 43, 4, 0.4) -9.22%, rgba(255, 170, 0, 0.4) 63.27%);
			z-index: 1;
		}
	}

	.aside-bottom {
		border-radius: 20px;
		background: linear-gradient(108deg, #fa0 7.37%, #b68a00 95.19%);
		box-shadow: 0px -24px 18px 0px rgba(254, 196, 79, 0.08);

		.inner {
			border-radius: 20px;

			.subtitle {
				max-width: 163px;
				margin: 0 auto 35px;
				font-size: 1rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.16px;
				text-align: center;
			}

			.pro-button {
				border-radius: 20px;
				background: #fff;
				box-shadow: 2px 4px 4px 0px rgba(79, 42, 234, 0.17);
				height: 40px;
				border: none;
				outline: none;
				width: 100%;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				color: #000000;
			}

			padding: 40px 28px 22px;
		}
	}

	.user-dropdown {
		.btn {
			background: transparent !important;
			padding: 0;
			border: none;
			display: flex;
			align-items: center;
			gap: 5px;
			color: #000000;

			img {
				border-radius: 50%;
				object-fit: cover;
				aspect-ratio: 1;
			}
		}

		@include breakpoint(max-lg) {
			.btn {
				&::after {
					display: none;
				}
			}
		}
	}

	.header-section {
		display: flex;
		align-items: center;
		gap: 20px;
		padding-block: 10px 0;
		padding-bottom: 10px;
		font-size: 16px;
		position: sticky;
		top: 0;
		z-index: 99;
		transition: all ease 0.3s;

		// &.active {
		// 	background: rgba(#0d0b06, 0.95);
		// 	padding-block: 16px;
		// }
		.title {
			margin: 0;
			color: #000000;

			@include breakpoint(xxl) {
				font-size: 40px;
			}
		}

		.header-right {
			display: flex;
			align-items: center;
			gap: 20px;

			@include breakpoint(max-sm) {
				gap: 10px;

				.premium-btn {
					font-size: 13px;
				}

				.user-dropdown {
					.btn {
						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}

		@include breakpoint(max-sm) {
			gap: 10px;
		}
	}

	.search-form {
		flex-grow: 1;
		width: 200px;
		max-width: 732px;
		margin: 0 auto;
		font-size: 16px;

		.form-control {
			height: 42px;
			background: rgba(255, 255, 255, 1);
			// color: #000000;
			color: #000000;
			border: 0px solid #000000;
			border-top-color: #000000;
			border-right-color: #000000;
			border-left-color: #000000;
			box-shadow: none;

			&::placeholder {
				color: #000000;
			}

			padding-left: 45px;

			@include breakpoint(max-sm) {
				height: 35px;
				font-size: 14px;
				padding-left: 35px;
			}
		}

		.submit--btn {
			position: absolute;
			top: 0;
			left: 0;
			height: 42px;
			width: 42px;
			background: transparent;
			border: none;
			outline: none;
			box-shadow: none;

			svg {
				stroke: #000000;
				color: #000000 !important;
			}

			@include breakpoint(max-sm) {
				height: 35px;
				width: 35px;
			}
		}
	}

	.dasboard-bg {
		position: absolute;
		top: 0;
		left: 0;
		height: 100dvh;
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		img {
			position: absolute;
			// max-width: 914px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.premium-btn {
		border-radius: 7px;
		background: rgba(20, 255, 0, 0.28);
		padding: 7px 10px;
		display: flex;
		align-items: center;
		border: none;
		outline: none;
		box-shadow: none;
		color: #2F8307;
		font-size: 16px;
		gap: 4px;

		svg {
			color: #2F8307;
		}
	}

	.section-wrapper {
		position: relative;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.item-left {
			width: 100%;
			position: relative;
			z-index: 1;

			@include breakpoint(sm) {
				max-width: 430px;
			}
		}

		.item-right {
			width: 100%;
			border-radius: 42px;
			border: 0px solid #ffc100;
			background: #FFFFFF;
			padding: 36px 26px 24px;
			position: relative;
			z-index: 1;

			.text-white {
				color: #000000 !important;

				svg {
					fill: #000000 !important;
				}
			}

			@include breakpoint(sm) {
				max-width: 328px;
			}
		}

		@include breakpoint(max-xl) {
			.item-left {
				max-width: 100%;
			}

			.location-map {
				width: 100%;
				height: 400px;

				img {
					top: 62%;
				}
			}

			.item-right {
				max-width: 100%;

			}
		}

		@include breakpoint(max-sm) {
			.location-map {
				height: 00px;
			}
		}
	}

	.total-member {
		border-radius: 35px;
		border: 0px solid #ffc100;
		background: #FFFFFF;
		backdrop-filter: blur(10.5px);
		padding: 28px;
		font-size: 16px;

		.subtitle {
			flex-grow: 1;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.8px;
			color: #000000;
			position: relative;
			z-index: 1;
		}

		.form-select {
			width: unset;
			padding: 0;
			padding-right: 12px;
			// background-color: transparent;
			color: var(--white);
			border: none;
			box-shadow: none;
			background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") no-repeat right center / 14px;
			text-transform: uppercase;

			option {
				color: var(--body);
			}

			position: relative;
			z-index: 1;
		}

		.form-select-read-more {
			width: unset;
			padding: 0;
			padding-right: 12px;
			background-color: transparent;
			color: var(--white);
			border: none;
			box-shadow: none;
			text-transform: uppercase;

			option {
				color: var(--body);
			}

			position: relative;
			z-index: 1;
		}

		.title {
			font-size: 48px;
			margin-top: 16px;
			position: relative;
			color: #000000;
			z-index: 1;

			span {
				font-weight: 400;
				color: #000000 !important;
			}
		}

		position: relative;

		.arrow {
			position: absolute;
			top: 64px;
			right: 30px;
		}

		margin-bottom: 24px;

		@include breakpoint(xxl) {
			margin-bottom: 36px;
		}

		span {
			color: #000000 !important;
		}
	}

	.top-affiliates {
		.title {
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: 0.96px;
			margin-bottom: 16px;
			color: #000000;

			@include breakpoint(xxl) {
				margin-bottom: 24px;
			}
		}
	}

	.text-success {
		color: #0da300 !important;
	}

	.affiliates {
		display: flex;
		flex-direction: column;
		gap: 18px;

		.affiliate-item {
			display: flex;
			align-items: center;
			gap: 20px;
			color: #fff;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.64px;

			.img {
				width: 56px;
				height: 56px;
				border-radius: 13px;
				object-fit: cover;
				aspect-ratio: 1;
			}

			.cont {
				padding-top: 5px;
			}

			.name {
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.64px;
				flex-grow: 1;
				margin-bottom: 6px;
				color: #000000;
			}

			.amount {
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.64px;
				color: #000000;
			}
		}
	}

	.stock-market {
		border: 0px solid #ffc100;
		background: #FFFFFF;
		backdrop-filter: blur(10.5px);
		padding: 24px 18px;
		border-radius: 42px;
		margin-top: 24px;

		@include breakpoint(xxl) {
			margin-top: 36px;
		}

		.title {
			color: #000000;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.8px;
			margin-bottom: 21px;
		}
	}

	.market {
		display: flex;
		flex-direction: column;
		gap: 14px;
		--special-clr: #50ff00;

		.market-item {
			border-radius: 12px;
			background: #FFC100;
			display: flex;
			align-items: center;
			padding: 11px 17px;
			gap: 17px;

			.name {
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				width: 40px;
				text-align: center;
				margin-right: 17px;
				line-height: 1;
				color: #000000;
			}

			font-size: 16px;

			.profit,
			.time {
				color: #000000;
			}

			.price {
				font-size: 32px;
				font-style: normal;
				font-weight: 500;
				color: #000000;
			}

			.price {
				margin: 0;
				color: #000000;
			}

			position: relative;

			.arrow {
				color: var(--special-clr);
				position: absolute;
				left: calc(50% + 26px);
				bottom: 36px;

				svg {
					width: 85px;
				}
			}
		}
	}

	.w-0 {
		width: 0;
	}

	.schedules {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.schedule-item {
			border-radius: 12px;
			background: linear-gradient(93deg, rgba(55, 43, 4, 0.12) -7.39%, rgba(255, 170, 0, 0.12) 2.35%);
			padding: 7px;
			color: #000000;
			font-size: 12px;

			.time {
				text-transform: uppercase;
			}

			.name {
				color: #fff;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-top: 5px;
				margin-bottom: 11px;
			}

			display: flex;
			gap: 8px;

			.img {
				border-radius: 12px;
				width: 79px;
			}

			.cont {
				width: 0;
				flex-grow: 1;
			}

			.cont,
			img {
				transition: all ease 0.3s;
			}

			&:hover {

				.cont,
				img {
					opacity: 0.6;
				}
			}
		}
	}

	.inline-date {
		.react-datepicker {
			width: 100%;
			background: transparent;

			.react-datepicker__month {
				margin-inline: 0;
			}

			.react-datepicker__month-container {
				width: 100%;

				.react-datepicker__current-month {
					color: #000000;
				}
			}

			.react-datepicker__day-names {
				display: flex;
				padding-top: 20px;

				.react-datepicker__day-name {
					color: #000000;
					flex-grow: 1;
					width: 0;
				}
			}

			.react-datepicker__header {
				background: transparent;
				border: none;

				.react-datepicker__current-month {
					font-size: 20px;
					transform: translateY(-11px);
				}
			}

			text-align: center;
			border: none;

			.react-datepicker__day {
				color: #000000;

				&:hover {
					background: rgba(255, 193, 0, 0.33) !important;
				}
			}

			.react-datepicker__day--disabled {
				color: #000000;
			}

			.react-datepicker__day--keyboard-selected,
			.react-datepicker__day--selected {
				border-radius: 7px !important;
				background-color: #FFC100 !important;
			}

			.react-datepicker__week {
				display: flex;
			}

			.react-datepicker__day {
				margin-block: 7px;
				flex-grow: 1;
				width: 0;
			}

			.react-datepicker__navigation {
				color: #000000;
				width: 22px;
				height: 22px;
				border: 1px solid #000000;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 5px;

				.react-datepicker__navigation-icon {
					&::before {
						border-color: #000000;
						border-width: 1px 1px 0 0;
					}

					left: 0;
				}
			}

			.react-datepicker__year-read-view--down-arrow,
			.react-datepicker__month-read-view--down-arrow,
			.react-datepicker__month-year-read-view--down-arrow,
			.react-datepicker__navigation-icon::before {
				top: 12px;
			}
		}
	}

	.events-date {
		display: flex;
		align-items: center;
		gap: 10px;

		.time {
			color: #000000;

			svg {
				color: #000000;
				fill: #000000;
			}
		}

		.day {
			border-radius: 5px;
			background: #fffefc;
			width: 43px;
			height: 43px;

			.num {
				color: #010101;
				font-size: 24px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			text-align: center;
			color: #010101;
			text-transform: uppercase;
		}

		font-size: 12px;
		line-height: normal;
		font-style: normal;
		font-weight: 400;

		.cont {
			color: #000000;

			h6 {
				color: #000000;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-bottom: 2px;
			}
		}
	}

	.location-map {
		width: 0;
		flex-grow: 1;
		position: relative;
		display: flex;
		justify-content: center; /* Horizontal centering */
		align-items: center; /* Vertical centering */
		height: 90vh; /* Set the container height to the viewport height */
		img {
			//transform: translate(-52%, -60%);
			max-width: 70vw;
		}

		@media screen and (max-width: 1750px) {
			img {
				max-width: 70vw;
				top: 60%;
			}
		}

		@media screen and (max-width: 1440px) {
			img {
				max-width: 70vw;
				top: 40%;
			}
		}

		@media screen and (max-width: 992px) {
			img {
				max-width: 90vw;
			}
		}

		@media screen and (max-width: 575px) {
			img {
				max-width: 130vw;
			}
		}
	}

	.dashboard-bg {
		background-image: none !important;
		background-color: #F5F8FF !important;

		position: fixed;
		top: 0;
		left: 0;
		height: 100dvh;
		width: 100vw;
		opacity: 0.8;
	}

	.form--select-control {
		border-radius: 6px;
		background: rgba(255, 193, 0, 0.4);

		.form--control {
			background-color: transparent;
			--bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
			background-size: 16px;
			height: 39px;
			font-size: 16px;
			padding-block: 0;
			font-weight: 400;
			border: none;
			padding-inline: 17px 37px;
		}

		&.text {
			background-color: transparent;
			border: none;

			.form-select {
				--bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffc100%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
				padding-left: 0;
			}
		}

		.icon-base.form-select {
			--bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffc100%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
		}
	}

	.tutor-slider-wrapper {
		border-radius: 28px;
		border: 1px solid #000000;
		background: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
		padding: 45px;

		@include breakpoint(max-xxl) {
			padding: 35px;
		}

		@include breakpoint(max-xl) {
			padding: 35px 25px;
		}

		@include breakpoint(max-xl) {
			padding: 35px 20px;
		}

		.top {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			align-items: center;
			justify-content: space-between;

			.title {
				margin: 0;
			}

			margin-bottom: 52px;

			@include breakpoint(max-xl) {
				margin-bottom: 36px;
			}

			@include breakpoint(max-md) {
				margin-bottom: 25px;
			}
		}
	}

	.tutor-slider-item {
		border: 1px solid #000000;
		border-radius: 24px;
		padding: 14px 10px 24px;

		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		.img {
			border-radius: 21px;
			overflow: hidden;
			margin-bottom: 26px;

			img {
				width: 100%;
				aspect-ratio: 315/209;
			}

			position: relative;

			.wishlist {
				position: absolute;
				right: 20px;
				top: 20px;
				color: #000000;
			}
		}

		.name {
			margin-bottom: 18px;
		}

		@include breakpoint(max-md) {
			font-size: 14px;
		}

		@include breakpoint(max-sm) {
			.img {
				margin-bottom: 20px;
			}

			.name {
				margin-bottom: 12px;
			}
		}
	}

	.no-gutter {
		background: transparent;
		--bs-gutter-x: 0;
		--bs-gutter-y: 0;
		border: none;
		outline: none;
		box-shadow: none;
	}

	.slider-wrapper {
		position: relative;

		.arrow-left,
		.arrow-right {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		.arrow-left {
			left: -32px;
		}

		.arrow-right {
			right: -32px;
		}

		@include breakpoint(max-xl) {
			padding-inline: 16px;

			.arrow-left {
				left: -16px;
			}

			.arrow-right {
				right: -16px;
			}
		}
	}

	.mt-lg-55 {
		@include breakpoint(lg) {
			margin-top: 55px !important;
		}
	}

	.affiliate-post-wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;

		@include breakpoint(lg) {
			gap: 55px;
		}
	}

	.affialite-post {
		display: flex;
		flex-wrap: wrap;

		.img {
			width: 25%;
			max-width: 479px;
			border-radius: 28px 0 0 28px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.cont {
			width: 0;
			flex-grow: 1;
			border-radius: 0 28px 28px 0;
			border: 1px solid #ffc100;
			background: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
			padding: 58px 52px;
			border-left: none;

			.author {
				display: flex;
				align-items: center;
				margin-bottom: 20px;

				@include breakpoint(xl) {
					margin-bottom: 28px;
				}

				@include breakpoint(xxl) {
					margin-bottom: 44px;
				}

				img {
					width: 92px;
					aspect-ratio: 1;
					object-fit: cover;

					@include breakpoint(max-sm) {
						width: 75px;
					}
				}

				.name {
					@include breakpoint(xxl) {
						font-size: 32px;
					}

					//line limit 2
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin: 0;
					width: 0;
					flex-grow: 1;
				}

				gap: 20px;

				@include breakpoint(xxl) {
					gap: 30px;
				}
			}

			.txt {
				font-size: 18px;

				@include breakpoint(xxl) {
					font-size: 32px;
					line-height: 1.2;
				}

				//line limit 2
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				margin: 0;
				font-weight: 400;
			}
		}

		@include breakpoint(max-xxl) {
			.cont {
				padding: 35px 25px;

				.author {
					margin-bottom: 25px;
				}
			}
		}

		@include breakpoint(max-sm) {
			.img {
				border-radius: 20px 20px 0 0;
				width: 100%;
				max-width: 100%;
			}

			.cont {
				width: 100%;
				border-radius: 0 0 20px 20px;
				border-top: none;
				border-left: 1px solid #000000;
			}
		}
	}

	.outline-btn,
	.base-btn {
		height: 49px;
		padding-inline: 13px;
		border-radius: 11px;
		border: 1px solid #ffc100;
		display: flex;
		justify-content: center;
		gap: 9px;
		align-items: center;
		color: var(--white);

		@include breakpoint(max-sm) {
			height: 38px;
			font-size: 14px;

			svg {
				width: 20px;
				height: unset;
			}
		}
	}

	.meta-area {
		margin-top: 28px;
		font-size: 16px;

		.text-small {
			font-size: 14px;
		}

		@include breakpoint(xxl) {
			margin-top: 45px;
			font-size: 20px;

			.text-small {
				font-size: 16px;
			}
		}

		@include breakpoint(max-sm) {
			font-size: 13px;

			.text-small {
				font-size: 11px;
			}
		}
	}

	.base-btn {
		background: #000000;
	}

	.affiliate-row {
		@include breakpoint(xxl) {
			--bs-gutter-x: 34px;
			--bs-gutter-y: 30px;
		}
	}
	.iTXXov .video-description {
		background-color: #FFFFFF;
		border-radius: 15px;
		p{
			color: #000;
		}
	}


	.iTXXov .right-sidebar{
		background: #FFFFFF !important;
		.top-bar{
			.btn{
				color: #000000;
			}
		}

	}
	.dark_mode{
		display: none;
	}
	.light_mode{
		display: block;
	}
	.video-items{
		h1{
			color: #000000;
		}
	}
	.kHmtry {
		background-color: #FFFFFF;
		border-radius: 10px;
		a,h1,h2,h3,h4,h5,h6,span,p{
			color: #000000 !important;
		}
		.content {
			a,h1,h2,h3,h4,h5,h6,span,p{
				color: #000000 !important;
			}
		}
	}
	.like_details {
		a,h1,h2,h3,h4,h5,h6,span,p{
			color: #000000 !important;
		}
	}
	.iQchcv .right-sidebar{
		background: #FFFFFF;
		.btn-prev, .btn-next {
			border: 2px solid #000000;
			color: #000000;
			font-size: 18px;
			border-radius: 10px;
		}
		a,h1,h2,h3,h4,h5,h6,span{
			color: #000000;
		}
		.name{
			color: #000 !important;
		}
		.day{
			color: #000 !important;
		}
	}
	.calender{
		a,h1,h2,h3,h4,h5,h6,span{
			color: #000000;
		}
	}
	.__card {
		border-radius: 28px;
		border: 1px solid rgba(#ffc100, 0.5);
		border-top: 1px solid #ffc100;
		background: #fff;
		padding: 37px;

		@include breakpoint(max-xxl) {
			padding: 35px 20px;
		}

		height: 100%;
	}

	.mt-30 {
		margin-top: 30px;
	}

	.mb-40 {
		margin-bottom: 40px;
	}

	.copy-input {
		.form-control {
			height: 50px;
			border-radius: 5px;
			border: 1px solid #000000;
			outline: none;
			box-shadow: none;
			background: transparent;
			color: var(--white);
			padding-right: 60px;
		}

		button {
			position: absolute;
			right: 0;
			top: 0;
			height: 50px;
			width: 50px;
			background: transparent;
			outline: none;
			box-shadow: none;
			border: none;
		}
	}

	.text-16 {
		font-size: 16px !important;
	}

	.balance-card {
		display: flex;
		align-items: center;
		gap: 26px;
		padding: 12px 18px;

		.inner-card {
			border-radius: 23px;
			// border: 1px solid #000000;
			background: rgba(#d9d9d9, 0.05);
			padding: 19px;
			text-align: center;
			flex-grow: 1;
			h5{
				color: #000000 !important;
			}
			h6{
				color: #000000 !important;
			}
			.balance {
				font-style: normal;
				font-weight: 700;
				margin: 0;
				line-height: 1;
				font-size: 30px;

				@include breakpoint(xl) {
					font-size: 32px;
				}

				@include breakpoint(xxl) {
					font-size: 2.4vw;
				}
			}
		}

		@include breakpoint(max-xxl) {
			gap: 14px;

			img {
				width: 60px;
			}

			.inner-card {
				padding: 14px;

				.text-16 {
					font-size: 14px !important;
					font-weight: 500;
				}

				.balance {
					font-size: 24px;
				}
			}
		}

		@media screen and (max-width: 450px) {
			gap: 14px;
			padding: 14px;

			img {
				width: 40px;
			}

			.inner-card {
				padding: 12px;

				.text-16 {
					font-size: 11px !important;
				}

				.balance {
					font-size: 20px;
				}
			}
		}

		@media screen and (max-width: 350px) {
			img {
				margin: 0 auto;
				width: 40px;
			}

			flex-wrap: wrap;

			.inner-card {
				width: 100%;
				max-width: 100%;
				border-radius: 20px;

				.text-16 {
					font-size: 11px !important;
				}

				.balance {
					font-size: 20px;
				}
			}
		}
	}

	.table-card-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
		flex-wrap: wrap;

		.form--select-control {
			border-radius: 7px;
			border: 1px solid #ffc100;
			background-color: transparent !important;

			.form-select {
				text-transform: capitalize;
				background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffc100%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") no-repeat calc(100% - 14px) center / 14px;
			}
		}
	}

	.__table {}

	body * {
		::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}

		::-webkit-scrollbar-track {
			background: #ffffff30;
		}

		::-webkit-scrollbar-thumb {
			background: #ffc100;
			border-radius: 10px;
		}

		::-webkit-scrollbar-thumb:hover {
			background: #ffc100;
		}
	}

	.table-bottom-bordered {
		min-width: 500px;
		margin: 0;

		tr {
			th {
				font-size: 16px;
				background: transparent;
				color: #000000;
				font-weight: 400;
				border: none;
				padding: 20px 0;
			}

			td {
				font-size: 16px;
				border-bottom: 1px solid #734f06;
				padding: 20px 0;

				a {
					background: transparent;
					color: var(--white);

					&:hover {
						color: #000000;
					}
				}

				&.border-0 {
					padding: 12px 0;
				}
			}

			.badge {
				height: 31px;
				min-width: 81px;
				color: #3f260e;
				border-radius: 9px;
				background: #ad8300;
			}
		}
	}

	.feed-row {
		.__card {
			background-color: #fff;
		}

		@include breakpoint(xxl) {
			--bs-gutter-x: 49px;
		}
	}

	.post-form {
		.form-control {
			border-radius: 18px;
			border: 1px solid rgba(#ffc100, 0.1);
			background: #88869f2e;
			min-height: 208px;
			resize: none;
			box-shadow: none;
			outline: none;
			padding: 40px 40px 65px 81px;
			color: var(--white);

			&::placeholder {
				color: #a7a7a7;
			}
		}

		.send-btn {
			background-color: #FFC100;
		}

		.post-author {
			position: absolute;
			width: 42px;
			aspect-ratio: 1;
			object-fit: cover;
			border-radius: 50%;
			top: 31px;
			left: 28px;
		}

		@include breakpoint(max-sm) {
			.post-author {
				width: 30px;
				left: 20px;
				top: 20px;
			}

			.form-control {
				font-size: 14px;
				padding-top: 24px;
				padding-left: 60px;
				padding-right: 20px;
			}
		}

		.send-form-bottom {
			position: absolute;
			right: 0;
			bottom: 0;
			padding-bottom: 23px;
			width: 100%;
			padding-inline: 29px 37px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			z-index: 1;
			gap: 13px;
			align-items: center;

			.send-btn {
				margin-left: auto;
			}

			@include breakpoint(max-sm) {
				padding: 0 15px 15px;
				gap: 10px;

				.send-btn {
					padding: 7px 14px;
					font-size: 14px;

					svg {
						width: 16px;
					}
				}
			}
		}
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.send-btn {
		border-radius: 12px;
		background: #fff;
		padding: 7px 23px;
		color: #000;
		display: flex;
		justify-content: center;
		gap: 7px;
		align-items: center;
		font-size: 16px;
		outline: none;
		box-shadow: none;
		border: none;
		font-weight: 400;
	}

	.post-form-card {
		@include breakpoint(max-xl) {
			padding: 24px;
		}

		@include breakpoint(max-md) {
			padding: 16px 16px 24px;
		}
	}

	.no--gutter {
		background: transparent !important;

		&::after {
			display: none;
		}

		padding: 0;
		margin: 0;
	}

	.dropdown-menu,
	:root {
		--bs-dropdown-link-active-bg: #000000;
	}

	.post-item-area {
		display: flex;
		flex-direction: column;
		gap: 30px;

		.post-item {
			padding-bottom: 30px;
			border-bottom: 1px solid #988138;
		}

		@include breakpoint(xl) {
			gap: 60px;

			.post-item {
				padding-bottom: 60px;
			}
		}

		.dropdown {
			.dropdown-toggle {
				circle {
					fill: #000000;
				}
			}
		}
	}

	.post-item {
		&-top {
			display: flex;
			justify-content: space-between;
			gap: 8px;
			margin-bottom: 25px;

			.img {
				width: 42px;
				position: relative;

				.icon {
					position: absolute;
					bottom: -3px;
					right: -3px;
				}

				img {
					width: 100%;
					aspect-ratio: 1;
					object-fit: cover;
				}
			}

			.cont {
				width: 0;
				flex-grow: 1;
			}

			.dropdown-toggle {
				@include breakpoint(max-md) {
					svg {
						max-height: 20px;
					}
				}
			}

			.post-author {
				display: flex;
				flex-grow: 1;
				width: 0;
				gap: 8px;
				color: #a7a7a7;
				font-size: 14px;

				.name {
					font-size: 18px;
					font-weight: 500;
					margin: 0;
				}
			}
		}

		&-body {
			letter-spacing: 0.36px;

			.subtitle {
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 26px;
				letter-spacing: 0.36px;
				color: #000000;
			}

			.text {
				margin-bottom: 26px;
				color: #000000;
			}

			.post-img {
				border-radius: 14px;
				margin-bottom: 26px;
				max-width: 100%;
			}

			@include breakpoint(max-xl) {

				.text,
				.subtitle {
					font-size: 16px;
				}
			}
		}
	}

	.post-icons {
		display: flex;
		justify-content: space-between;
		gap: 16px;
		flex-wrap: wrap;

		button {
			display: flex;
			align-items: center;
			gap: 7px;
			color: #000000;

			svg {
				width: 24px;
				height: 24px;
			}
		}

		@include breakpoint(max-sm) {
			gap: 8px;
			font-size: 14px;

			button {
				gap: 4px;

				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	select {
		option {
			background: #1b1609;
			color: var(--white);
		}
	}

	.best-tutors {
		background: rgb(227 221 221 / 74%) !important;
	}

	.educators-area {
		background: #fff !important;

		span.css-xl71ch {
			color: #000000;
		}

		span.chakra-button__icon.css-1hzyiq5 {
			svg {
				color: #000000;
			}
		}

		.items {
			background: #F3F3F5;

			.top-bar {
				h1 {
					color: #000000;
				}
			}

			.details {
				p {
					color: #000000;
				}
			}

			.btn {
				color: #000000;
				;
			}

			.btn-outline-primary {
				color: #000000;
			}

		}

		.pagination {
			.btn {
				color: #000000;
				;
			}

			.page-link {
				color: #000000;
				border-color: #000000;
			}
		}
	}

	.activity-card {
		border-radius: 28px;
		border: 1px solid rgba(#ffc100, 0.1);
		background: linear-gradient(191deg, rgba(55, 43, 4, 0.04) -18.52%, rgba(255, 170, 0, 0.04) 52.54%);

		.__card {
			border-top: none;
			border-left: none;
			border-right: none;
		}

		.activities {
			padding: 45px 40px 36px;

			@include breakpoint(max-xl) {
				padding-inline: 25px;
			}

			.item {
				gap: 20px;
				display: flex;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				letter-spacing: 0.64px;
				padding-bottom: 50px;
				align-items: flex-start;
				position: relative;

				&::before {
					width: 1px;
					height: 100%;
					content: "";
					position: absolute;
					left: 29px;
					border-right: 1px dashed var(--white);
					z-index: -1;
				}

				&:last-child {
					&::before {
						display: none;
					}

					padding-bottom: 27px;
					border-bottom: 1px solid #887432;
				}

				.time {
					color: #000;
					padding: 5px 10px;
					background: #000000;
					width: 59px;
					text-align: center;
				}

				.title {
					font-size: 20px;
					margin-bottom: 14px;
				}
			}
		}
	}

	.suggestions {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.cont {
			width: 0;
			flex-grow: 1;
			align-items: center;

			.name {
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 4px;
			}

			color: #a7a7a7;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.28px;
		}

		.img {
			width: 42px;

			img {
				width: 100%;
				aspect-ratio: 1;
				object-fit: cover;
				border-radius: 50%;
			}
		}

		button {
			color: #ffc100;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.36px;
			border-radius: 6px;
			background: #5a4008;
			padding: 10px;
			width: 98px;
		}
	}

	.trending-feeds {
		.item {
			gap: 14px;

			&:not(:last-child) {
				margin-bottom: 30px;
			}
		}

		.img {
			width: 86px;

			img {
				width: 100%;
				border-radius: 9px;
			}
		}

		.cont {
			width: 0;
			flex-grow: 1;

			.name {
				font-size: 24px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				letter-spacing: 0.96px;
				margin-bottom: 9px;
				// line limit 2
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.info {
				color: #d3a926;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.64px;
				display: block;
			}
		}

		@include breakpoint(max-sm) {
			.cont {
				.name {
					font-size: 20px;
				}
			}
		}

		@include breakpoint(max-xxl) {
			@include breakpoint(xl) {
				.cont {
					.name {
						font-size: 20px;
					}
				}
			}
		}
	}

	.volumes-wrapper {
		padding: 25px 42px;
		background: linear-gradient(285deg, rgba(55, 43, 4, 0.4) -11.25%, rgba(254, 196, 79, 0.4) 110.63%);
		stroke-width: 1px;
		stroke: #ffc100;
		backdrop-filter: blur(10.5px);
		border-radius: 35px;

		@include breakpoint(max-lg) {
			padding: 25px 25px;
		}

		@include breakpoint(max-sm) {
			padding: 25px 20px;
		}
	}

	.volumes {
		display: flex;

		.item {
			border-radius: 26px;
			background: #33270c;

			.img {
				border-radius: 26px 26px 0px 0px;
				overflow: hidden;

				img {
					max-width: 100%;
				}

				position: relative;

				.logo {
					position: absolute;
					left: 15px;
					top: 15px;
				}
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 700;
				color: #fff;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;

				line-height: 24px;
			}

			.text {
				color: #e1e1e1;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 19px;
				// line limit 2
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				margin-bottom: 17px;
			}

			color: #b8b8b8;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 19px;

			.lesson,
			.duration {
				display: inline-flex;
				gap: 3px;
				align-items: center;
			}

			.cont {
				border-radius: 0 0 26px 26px;
				background: #33270c;
				padding: 16px 18px;
			}
		}
	}

	.education-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 31px;
		border-radius: 40px;
		background: linear-gradient(180deg, #372b04 0%, #bc7f01 113.4%);
		padding: 35px 30px;

		.title {
			@include breakpoint(xl) {
				font-size: 26px;
				margin-bottom: 7px;
			}
		}

		.left {
			width: 58%;
			max-width: 568px;

			@include breakpoint(max-xxl) {
				flex-grow: 1;
			}

			iframe {
				border-radius: 29px;
				display: inline-flex;
				margin-bottom: 26px;
			}

			font-size: 16px;
		}

		.right {
			width: 200px;
			flex-grow: 1;
			color: #fff;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		@include breakpoint(max-md) {
			padding: 24px;

			.left,
			.right {
				width: 100%;
			}
		}

		@include breakpoint(max-sm) {
			padding: 16px;

			iframe {
				height: 240px;
			}
		}
	}

	.video-iframe {
		border-radius: 29px;
		display: inline-flex;
		margin-bottom: 66px;

		@include breakpoint(max-xl) {
			height: 360px;
			margin-bottom: 26px;
		}

		@include breakpoint(max-md) {
			height: 260px;
			margin-bottom: 26px;
		}
	}

	.lesson-excercise {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.item {
			display: flex;
			align-items: center;
			gap: 16px;
			width: 100%;
			background: transparent;
			outline: none;
			box-shadow: none;
			border: none;
			text-align: left;
			color: var(--white);
			padding: 6px 14px;

			.serial {
				width: 31px;
				height: 31px;
				background-size: contain !important;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.info {
				width: 0;
				flex-grow: 1;

				.title {
					font-size: 20px;
					margin-bottom: 4px;
					color: var(--white);
				}

				font-size: 16px;
				font-style: normal;
				font-weight: 400;

				.time {
					color: #000000;
				}
			}

			&.active {
				border-radius: 9px;
				background: #fec44f;

				.title {
					color: #000;
				}

				color: #000;

				.serial {
					color: #fff;
					filter: invert(1);
				}

				--base: #ff1f00;
			}
		}

		@include breakpoint(max-xxl) {
			gap: 4px;

			.item {
				.info {
					.title {
						font-size: 16px;
					}

					font-size: 13px;
				}
			}
		}
	}

	.lesson-excercise-wrapper {
		max-height: 430px;
		overflow-y: auto;
	}

	.author-sidebar {
		border-radius: 42px;
		border: 1px solid #ffc100;
		background: linear-gradient(191deg, rgba(55, 43, 4, 0.4) -18.52%, rgba(255, 170, 0, 0.4) 52.54%);
		padding: 20px;

		.author-area {
			position: relative;

			.banner {
				border-radius: 42px;
				width: 100%;
				border: 1px solid #ffc100;
			}

			.author-img {
				border-radius: 129px;
				border: 4px solid #654605;
				position: relative;

				&::after {
					content: "";
					inset: 0;
					position: absolute;
					border-radius: 50%;
					background: linear-gradient(0deg, rgba(255, 193, 0, 0.3) 0%, rgba(255, 193, 0, 0.3) 100%);
				}

				width: 129px;

				img {
					width: 100%;
					aspect-ratio: 1;
					object-fit: cover;
				}

				margin: -65px auto 0;
			}
		}
	}

	.text-20 {
		font-size: 20px;
	}

	.education-row {
		@include breakpoint(xxl) {
			>.col-xl-8 {
				max-width: calc(100% - 458px);
				flex-grow: 1;
			}

			>.col-xl-4 {
				max-width: 458px;
			}
		}
	}

	.education-expand-row {
		@include breakpoint(xxl) {
			>.col-xl-8 {
				max-width: calc(100% - 500px);
				flex-grow: 1;
			}

			>.col-xl-4 {
				max-width: 500px;
			}
		}
	}

	.upcoming-classes {
		display: flex;
		flex-direction: column;
		gap: 17px;

		.item {
			display: flex;
			gap: 14px;
			align-items: center;

			img {
				width: 71px;
				height: 71px;
				aspect-ratio: 1;
				border-radius: 15px;
				object-fit: cover;
			}

			.cont {
				width: 0;
				flex-grow: 1;

				.title {
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					margin-bottom: 6px;
				}

				.tutor {
					color: #fec44f;
					font-size: 16px;
					display: block;
				}
			}
		}
	}

	.video-post-top {
		display: flex;
		flex-wrap: wrap;
		gap: 27px;
		align-items: center;
		justify-content: space-between;

		.post-author {
			position: relative;
			display: flex;
			gap: 18px;
			align-items: center;

			.cont {
				color: #000000;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;

				.name {
					margin-bottom: 8px;
				}
			}

			.img {
				position: relative;

				img {
					width: 82px;
				}

				.icon {
					position: absolute;
					bottom: 0;
					right: 0;

					svg {
						width: 26px;
						height: 26px;
					}
				}
			}
		}
	}

	.video-buttons {
		.base-btn {
			border-radius: 8px;
			background: linear-gradient(173deg, rgba(255, 193, 0, 0.4) -10.49%, rgba(255, 170, 0, 0.4) 74.76%);

			svg {
				width: 24px;
				height: 24px;
			}

			border: none;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			padding-inline: 17px;
		}

		.outline-btn {
			background: transparent;
			color: #000000;
			font-size: 16px;
			font-style: normal;
			padding-inline: 17px;
			font-weight: 400;
		}
	}

	.video-post-content {
		margin-top: 24px;

		.content-left {
			flex-grow: 1;
			width: 0;
			max-width: 650px;

			.title {
				margin-bottom: 24px;

				@include breakpoint(md) {
					font-size: 40px;
					font-style: normal;
					font-weight: 500;
					line-height: 43px;
					margin-bottom: 34px;
				}
			}

			p {
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				margin-bottom: 24px;

				@include breakpoint(md) {
					margin-bottom: 40px;
				}
			}
		}

		@include breakpoint(max-md) {
			.content-left {
				width: 100%;
			}

			.view-info {
				padding-top: 0;
			}
		}
	}

	.view-info {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		gap: 20px;
		width: 100%;
		max-width: 230px;
		padding-top: 30px;

		li {
			display: flex;
			align-items: center;
			gap: 19px;
			color: #887432;

			font-size: 16px;

			.icon {
				width: 37px;
				text-align: center;
			}
		}
	}

	.upcoming-videos-card {
		border-radius: 42px;
		border: 1px solid #ffc100;
		background: linear-gradient(191deg, rgba(55, 43, 4, 0.4) -18.52%, rgba(255, 170, 0, 0.4) 52.54%);
		padding: 43px 27px 65px;
		margin-bottom: 66px;

		@include breakpoint(max-xxl) {
			@include breakpoint(lg) {
				padding: 30px 18px;
			}
		}

		@include breakpoint(max-sm) {
			padding: 30px 18px;
		}
	}

	.upcoming-videos {
		display: flex;
		flex-direction: column;
		gap: 33px;

		.upcoming-video {
			display: flex;
			gap: 24px;
			align-items: flex-start;

			.img {
				position: relative;
				overflow: hidden;
				border-radius: 20px;
				width: 142px;

				.play-icon {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.cont {
				width: 0;
				flex-grow: 1;

				.title {
					font-size: 24px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					letter-spacing: 0.96px;
					color: var(--white);
					margin-bottom: 7px;
					// line limit 2
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}

				.tutor {
					color: #ad8919;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					letter-spacing: 0.64px;
					margin-bottom: 6px;
				}

				.views {
					color: #ad8919;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.64px;
				}
			}
		}

		@include breakpoint(max-xxl) {
			@include breakpoint(xl) {
				.upcoming-video {
					gap: 16px;

					.img {
						width: 100px;
					}

					.cont {
						.title {
							font-size: 18px;
						}

						.views {
							font-size: 13px;
						}
					}
				}
			}
		}

		@include breakpoint(max-sm) {
			.upcoming-video {
				gap: 16px;

				.img {
					width: 100px;
				}

				.cont {
					.title {
						font-size: 18px;
					}

					.views {
						font-size: 13px;
					}
				}
			}
		}
	}

	.tab-menu {
		padding-bottom: 21px;
		border-bottom: 1px solid #988138;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.96px;
		display: flex;
		flex-wrap: wrap;
		gap: 14px;

		li:not(:last-child) {
			border-right: 1px solid #a0a0a0;
			padding-right: 14px;
		}
	}

	.chatting-body {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		padding-block: 34px;
		border-bottom: 1px solid #988138;
	}

	.chatting {
		display: flex;
		gap: 12px;
		width: 100%;
		align-items: flex-start;

		.img {
			position: relative;
			width: 37px;

			img {
				width: 100%;
			}

			.icon {
				position: absolute;
				right: -2px;
				bottom: -7px;
			}
		}

		.cont {
			width: 0;
			flex-grow: 1;
			max-width: 272px;

			.name {
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				letter-spacing: 0.8px;
				margin-bottom: 9px;
				margin-top: 8px;

				.time {
					color: #ffc100;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.64px;
				}
			}

			.message {
				font-size: 16px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				// letter-spacing: 0.64px;
			}
		}

		&.my-text {
			flex-direction: row-reverse;

			.cont {
				text-align: right;
			}
		}
	}

	.search--form {
		width: 100%;
		margin-top: 27px;

		.form-control {
			height: 56px;
			border: 1px solid #000000;
			font-size: 16px;
			color: #000000;
			padding-left: 52px;
			padding-right: 52px;
		}

		.left-icon {
			position: absolute;
			left: 14px;
			top: 14px;
		}

		button {
			position: absolute;
			right: 14px;
			top: 14px;
		}
	}

	.date-slider-item {
		color: #ad8919;
		text-align: center;
		text-transform: uppercase;
		width: 64px;
		padding: 10px;
		background: transparent;
		border: none;
		border: 1px solid none;
		outline: none;

		&.active {
			color: #000000;
			border-radius: 8px;
			border: 1px solid #ffc10070;
			border-left: 1px solid #ffc10040;
			border-top: 1px solid #ffc10040;
			background: #ffc1003d;
			border-radius: 15px;
			display: inline-block;
		}
	}

	.chatting-page-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 40px;

		.chatting-page-left-sidebar {
			width: 30%;
			max-width: 375px;
			padding: 36px 13px;
			border-radius: 28px;
			border: 1px solid #ffbf0069;
			border-top: none;
			background: white;

			.search-form {
				width: 100%;
				margin-bottom: 19px;

				.form-control {
					height: 71px;
					color: var(--white);
					border-color: #000000;
					padding-inline: 20px;
					border-radius: 13px;

					&::placeholder {
						color: black;
					}
				}
			}

			.subtitle {
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				letter-spacing: 0.32px;
			}

			@include breakpoint(max-xl) {
				width: 36%;
			}

			@include breakpoint(max-md) {
				width: 100%;
				max-width: 100%;
				max-height: 500px;
				overflow-y: auto;
			}
		}

		.chatting-page-right-sidebar {
			width: 30%;
			max-width: 462px;
			border-radius: 28px;
			border: 1px solid #ffbf0069;
			border-top: none;
			background: linear-gradient(180deg, rgba(55, 43, 4, 0.31) -38.07%, rgba(255, 170, 0, 0.31) 88.45%);
			padding: 25px 23px;

			.banner {
				border-radius: 13px;
			}

			.author-img {
				padding: 3px;
				border: 2px dashed #ffc100;
				border-radius: 50%;
				max-width: 118px;
				margin: -58px auto 16px;
				position: relative;

				img {
					width: 100%;
					border-radius: 50%;
					aspect-ratio: 1;
					object-fit: cover;
					object-position: center center;
				}
			}

			.author-name {
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.32px;
			}

			.txt {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				max-width: 352px;
				margin-inline: auto;
			}

			.subtitle {
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.32px;
				margin-bottom: 23px;
			}

			@include breakpoint(max-xl) {
				width: 100%;
				// max-width: 100%;
				margin-inline: auto;
			}
		}
	}

	.mb-10 {
		margin-bottom: 10px;
	}

	.copy-link-input {
		.form-control {
			border-radius: 7px;
			background: #7b5b04;
			color: #ffb459;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			box-shadow: none;
			outline: none;
			border: none;
			height: 48px;
		}

		.btn {
			height: 40px;
			border-radius: 7px;
			background: rgba(255, 193, 0, 0.2);
			width: 109px;
			color: #ffc100;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 0;
			display: flex;
			align-items: center;
			gap: 3px;
			justify-content: center;
			position: absolute;
			right: 6px;
			top: 4px;

			&:active {
				border-color: #000000;
			}
		}

		position: relative;
	}

	.mb-55 {
		margin-bottom: 55px;
	}

	.photos-gallery {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		.photos-gallery-item {
			width: calc((100% / 3) - (20px / 3));
			position: relative;

			img {
				width: 100%;
				border-radius: 14px;
				cursor: pointer;
			}

			.view-all {
				background: rgba(#ffc100, 0.61);
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 14px;
				cursor: pointer;
				height: 100%;
				width: 100%;
			}
		}

		padding-bottom: 30px;
		border-bottom: 2px solid #000000;
	}

	.fs-14 {
		font-size: 14px;
	}

	.h-41 {
		height: 41px;
	}

	.user-list {
		&-item {
			&:not(:last-child) {
				margin-bottom: 46px;
			}

			.subtitle {
				font-size: 14px;
				font-weight: 600;
				color: #000000;
				display: flex;
				align-items: center;
				gap: 9px;
				justify-content: space-between;

				&::after {
					content: "";
					width: 0;
					flex-grow: 1;
					height: 1px;
					background: #ffc100;
					max-width: 274px;
				}
			}

			.user-list-item-cont {
				display: flex;
				flex-direction: column;
				gap: 21px;
				font-size: 14px;
				font-weight: 600;

				.user-list-item-cont-item {
					display: flex;
					align-items: center;
					gap: 11px;
					color: var(--white);

					img {
						border-radius: 50%;
						aspect-ratio: 1;
						object-fit: cover;
						border: 2px solid #000000;
						width: 34px;
					}

					&:hover {
						color: #000000;
					}
				}
			}

			&:first-child {
				.user-list-item-cont {
					.user-list-item-cont-item {
						color: #000000;
					}
				}
			}
		}
	}

	.search-hints {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;

		.search-hints-item {
			text-align: center;
			color: black;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.28px;

			.img {
				padding: 5px;
				width: 50px;
				border-radius: 50%;
				border: 1px solid #000000;
				margin: 0 auto 14px;

				img {
					width: 100%;
					aspect-ratio: 1;
					object-fit: cover;
					border-radius: 50%;
				}
			}

			&:first-child {
				.img {
					border-style: dashed;
				}
			}
		}
	}

	.chat-list-item {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 15px 11px;
		border-radius: 8px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.28px;

		&.active {
			background: #60440a;
			padding-left: 21px;
			color: white;
		}

		.img {
			position: relative;

			.new-msg-count {
				border: 1px solid #473205;
				background: #000000;
				width: 21px;
				height: 21px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				border-radius: 50%;
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}

		color: black;

		.cont {
			width: 0;
			flex-grow: 1;

			.message {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 12px;
				color: #7e590b;
			}
		}
	}

	.last-msg {
		// line limit 1
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.chat-wrapper {
		width: 280px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		.chat-header {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			gap: 16px;
			border-radius: 24px 20px 0px 0px;
			background: white;
			box-shadow: 0px 49px 18px 7px rgba(73, 73, 73, 0.05);
			padding: 16px 45px;
			color: #000000;
			font-size: 16px;

			.img {
				width: 54px;
				aspect-ratio: 1;
				object-fit: cover;
				border: 1px solid #ffc100;
				border-radius: 50%;
			}

			.name {
				font-size: 22px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.44px;
				margin-bottom: 3px;
				color: #0d0b06;
			}

			@media screen and (max-width: 1650px) {
				padding: 16px;

				.name {
					font-size: 18px;
				}
			}
		}

		.chat-body {
			flex-grow: 1;
			height: 400px;
			padding: 48px;
			overflow-y: auto;

			// hide scrollbar
			&::-webkit-scrollbar {
				width: 0px;
				background: transparent;
				/* make scrollbar transparent */
			}

			@media screen and (max-width: 1650px) {
				padding-inline: 16px;
			}

			@media screen and (max-width: 500px) {
				padding-inline: 0;
			}
		}
	}

	.gap-12px {
		gap: 12px;
	}

	.chat-form {
		.post-form {
			border-radius: 10px;
			background: white;
			border: 1px solid #ffbf0069;

			.form-control {
				background: transparent !important;
				border: none;
				height: 100px;
				min-height: 100px;
				padding-bottom: 0;

				@media screen and (max-width: 500px) {
					font-size: 12px;
				}
			}

			.send-form-bottom {
				position: relative;

				@media screen and (max-width: 500px) {
					svg {
						transform: scale(0.7);
					}
				}
			}
		}
	}

	.chat-items {
		display: flex;
		flex-direction: column;
		gap: 30px;

		@include breakpoint(xxl) {
			gap: 48px;
		}

		.chat-item {
			width: 90%;
			max-width: 511px;
			display: flex;
			gap: 11px;

			&.my-text {
				margin-inline-start: auto;
				flex-direction: row-reverse;
				text-align: right;
			}

			.img {
				width: 42px;

				img {
					width: 100%;
					aspect-ratio: 1;
					object-fit: cover;
					border-radius: 50%;
				}
			}

			.cont {
				width: 0;
				flex-grow: 1;

				.name {
					font-size: 16px;
					margin-bottom: 14px;

					.time {
						color: #949494;
						font-size: 14px;
					}
				}

				.message {
					background: #392905;
					padding: 10px 14px;
					border-radius: 10px;
					font-size: 14px;
					display: inline-block;
					margin-bottom: 21px;
				}

				@media screen and (max-width: 500px) {
					.name {
						font-size: 14px;
					}

					.message {
						font-size: 12px;
					}
				}
			}
		}
	}

	.today {
		color: #ffffff;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.28px;
		display: flex;
		align-items: center;
		gap: 10px;

		&::before,
		&::after {
			content: "";
			flex-grow: 1;
			height: 1px;
			background: #ffc100;
		}
	}

	.setting-wrapper {
		.box-groups {
			display: grid;
			grid-template-columns: 396px 1fr;
			gap: 30px;

			@media all and (max-width: 1300px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media all and (max-width: 991px) {
				grid-template-columns: repeat(1, 1fr);
			}
		}

		.box-item {
			border-radius: 28px;
			border: 1px solid #ffc100;
			background: linear-gradient(180deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);

			&:nth-child(1) {
				padding: 15px 13px 17px;
			}

			&:nth-child(2) {
				padding: 27px 30px 50px;

				@media all and (max-width: 525px) {
					padding: 27px 10px 50px;
				}
			}
		}

		.left-side {
			padding: 15px 13px 17px;
		}

		.img-contents {
			position: relative;
			margin-bottom: 91px;

			.cover-photo {
				width: 100%;
				border-radius: 22px;
			}
		}

		.change-cover-btn {
			position: absolute;
			top: 16px;
			right: 8px;
			background-color: transparent;
			color: #ffc100;
			font-family: Fira Sans;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: flex;
			padding: 5px 9px;
			align-items: center;
			gap: 4px;
			border-radius: 5px;
			border: 1px solid #ffc100;
		}

		.profile-img-content {
			position: absolute;
			max-width: 140px;
			max-height: 140px;
			bottom: -70px;
			left: 50%;
			transform: translate(-50%);
		}

		.dil-circle {
			position: relative;
		}

		.profile-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 23px;
			border-radius: 100%;
		}

		.change-profile-photo-btn {
			position: absolute;
			right: 23px;
			bottom: 23px;
			background-color: transparent;
			border: none;
			width: 30px;
			height: 30px;
			background-color: #ffc100;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		h4 {
			color: #fff;
			font-family: Fira Sans;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 1px;
			text-align: center;
			margin: 0;
			margin-bottom: 15px;
		}

		h5 {
			color: #ffc100;
			font-family: Fira Sans;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.7px;
			text-align: center;
			margin: 0;
			margin-bottom: 32px;
		}

		h6 {
			color: #fff;
			font-family: Fira Sans;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin: 0;

			@media all and (max-width: 1050px) {
				font-size: 14px;
			}

			@media all and (max-width: 375px) {
				font-size: 12px;
			}
		}

		span {
			color: #fff;
			font-family: Fira Sans;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;

			@media all and (max-width: 1050px) {
				font-size: 12px;
			}

			@media all and (max-width: 375px) {
				font-size: 10px;
			}
		}

		.number-contents {
			display: flex;
			justify-content: space-around;
			gap: 5px;

			.number-item {
				background-image: url("../public/img/setting/circle-conc.png");
				background-size: 100%;
				background-repeat: no-repeat;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				width: 97px;
				height: 97px;

				@media all and (max-width: 1050px) {
					width: 80px;
					height: 80px;
				}

				@media all and (max-width: 375px) {
					width: 77px;
					height: 77px;
				}
			}
		}

		.description {
			border-radius: 28px;
			border: 1px solid #ffc100;
			padding: 10px 22px 23px 22px;
			max-width: 407px;
			margin: 0 auto;
			margin-top: 47px;

			@media all and (max-width: 991px) {
				max-width: 100%;
			}

			h3 {
				color: #ffc100;
				font-family: Open Sans;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				border-bottom: 0.2px dashed rgba(255, 191, 0, 0.218);
				padding-bottom: 5px;
				margin-bottom: 11px;
			}

			p {
				color: #fff;
				font-family: Open Sans;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin: 0;
			}
		}

		.account-removal {
			max-width: 407px;
			margin: 0 auto;
			border-radius: 11px;
			background: #865b07;
			margin-top: 11px;
			padding: 22px 22px 21px 11px;

			@media all and (max-width: 991px) {
				max-width: 100%;
			}

			h3 {
				color: #fff;
				font-family: Fira Sans;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 113%;
				letter-spacing: 0.8px;
			}

			p {
				color: #ffc100;
				font-family: Fira Sans;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.6px;
				margin-bottom: 23px;
			}

			.btn-groups {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 16px;

				@media all and (max-width: 425px) {
					gap: 8px;
				}
			}

			button {
				background-color: transparent;
				color: #ffc100;
				font-family: Open Sans;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				padding: 13px;
				border-radius: 5px;
				border: none;

				@media all and (max-width: 425px) {
					padding: 8px 5px;
				}

				&:nth-child(1) {
					background: rgba(255, 180, 89, 0.3);
				}

				&:nth-child(2) {
					color: white;
					border: 1px solid #ffc100;
				}
			}
		}

		.right-side {
			h2 {
				color: #fff;
				font-family: Fira Sans;
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: 113%;
				letter-spacing: 1.2px;
			}

			h6 {
				color: #ffc100;
				font-family: Fira Sans;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 113%;
				/* 15.82px */
				letter-spacing: 0.7px;
				margin: 0;
			}

			input {
				color: #fff;
				font-family: Fira Sans;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 113%;
				background-color: transparent;
				border: none;
				outline: none;
				margin-top: 11px;
				display: block;
				width: 300px;

				@media all and (max-width: 1140px) {
					width: 100%;
				}

				@media all and (max-width: 525px) {
					width: 220px;
					font-size: 15px;
				}

				@media all and (max-width: 350px) {
					width: 100%;
				}
			}

			.input-groups {
				margin-top: 38px;
				display: flex;
				flex-direction: column;
				gap: 36px;
			}

			.input-layout {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 20px;
			}

			.edit-btn {
				border-radius: 5px;
				background: #a67e00;
				color: #fff;
				font-family: Open Sans;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				width: 113px;
				height: 45px;
				border: none !important;

				@media all and (max-width: 525px) {
					width: 80px;
					height: 36px;
				}
			}

			.two-step-verification {
				border-radius: 11px;
				background: #865b07;
				padding: 15px 22px 40px;
				margin-top: 27px;

				input {
					border-radius: 6px;
					border: 1px solid #ffc100;
					width: 100% !important;
					color: #ffc100;
					font-family: Fira Sans;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 113%;
					letter-spacing: 0.7px;
					padding: 14px 17px;

					&::placeholder {
						color: #ffc100;
					}
				}

				.btn-groups {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					margin-top: 35px;
					gap: 10px;

					button {
						color: #fff;
						font-family: Fira Sans;
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						letter-spacing: 1px;
						border-radius: 6px;
						border-radius: 6px;
						padding: 10px 20px;

						@media all and (max-width: 350px) {
							padding: 8px 10px;
						}

						&:nth-child(1) {
							background: #ffc100;
							border: 1px solid #ffb459;
						}

						&:nth-child(2) {
							border: 1px solid #ffc100;
							background-color: transparent;
						}
					}
				}

				h3 {
					color: #ffc100;
					font-family: Fira Sans;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 113%;
				}

				p {
					color: #ffc100;
					font-family: Fira Sans;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 113%;
					letter-spacing: 0.7px;
				}
			}

			.toggole {
				width: 48px;
				height: 24px;
				background-color: #ffc100;
				border-radius: 18px;
				display: flex;
				align-items: center;
				padding: 2px;
				cursor: pointer;
				transition: all 0.3s ease-in-out;

				@media all and (max-width: 575px) {
					width: 42px;
					height: 20px;
					padding: 1px 2px;
				}
			}

			.white-circle {
				width: 20px;
				height: 20px;
				background-color: white;
				border-radius: 100%;
				transition: all 0.3s ease-in-out;

				@media all and (max-width: 575px) {
					width: 15px;
					height: 15px;
				}
			}
		}

		.right-side-profile-circle {
			position: relative;
			width: 97px;
			height: 97px;

			.profile-img {
				padding: 20px;
			}

			.add-profile-btn {
				position: absolute;
				left: 0px;
				bottom: 18px;
				background-color: transparent;
				border: none;
				width: 26px;
				height: 26px;
				background-color: #ffc100;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.profile-wrapper {
		border-radius: 28px;
		border: 1px solid #ffc100;
		background: linear-gradient(180deg,
				rgba(55, 43, 4, 0.4) -38.07%,
				rgba(255, 170, 0, 0.4) 88.45%);

		.cover {
			width: calc(100% + 2px);
			height: 360px;
			object-fit: cover;
			border-radius: 28px;
			margin-left: -1px;
			margin-right: -1px;
			margin-top: -2px;
		}

		.main-content {
			padding-bottom: 120px;
			max-width: 1310px;
			padding-inline: 15px;
			margin: 0 auto;

			.profile-top-content {
				padding: 28px 42px;
				border-radius: 25px;
				background: rgba(96, 68, 10, 0.78);
				backdrop-filter: blur(2px);
				display: flex;
				flex-wrap: wrap;
				margin-top: -94px;
				position: relative;
				z-index: 1;

				.left-side {
					display: flex;
					align-items: center;
					gap: 24px;
					width: 45%;
					padding-right: 16px;

					.img {
						width: 140px;
						height: 140px;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							width: 95px;
							aspect-ratio: 1;
							object-fit: cover;
							border-radius: 50%;
						}
					}

					.cont {
						width: 0;
						flex-grow: 1;
						max-width: 275px;

						.name {
							font-size: 20px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							letter-spacing: 1px;
						}

						.badge-success {
							border-radius: 21px;
							background: rgba(13, 163, 0, 0.4);
							color: #14ff00;
							font-size: 13px;
							font-style: normal;
							font-weight: 400;
						}

						.designation {
							display: block;
							color: #ffc100;
							font-size: 14px;
							letter-spacing: 0.7px;
						}

						.text {
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
						}
					}
				}

				.count-area {
					flex-grow: 1;

					.seperator {
						width: 1px;
						height: 95px;
						background: #000000;
					}
				}

				@include breakpoint(max-xl) {
					padding-inline: 24px;
				}

				@include breakpoint(max-xl) {
					gap: 32px;

					.count-area,
					.left-side {
						width: 100%;
						max-width: 100%;

						.cont {
							max-width: 100%;
						}

						.seperator:first-child {
							display: none;
						}
					}
				}

				@include breakpoint(max-sm) {
					gap: 16px;
					padding-inline: 16px;

					.left-side {
						.img {
							width: 80px;
							height: 80px;

							img {
								width: 60px;
							}
						}

						@media screen and (max-width: 424px) {
							flex-wrap: wrap;

							.cont {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	.count-area {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
		justify-content: space-between;

		.count-item {
			width: 120px;
			height: 120px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.count {
				font-weight: 600;
				font-size: 16px;
			}

			.subtext {
				font-size: 14px;
			}
		}

		@include breakpoint(max-sm) {
			@media screen and (max-width: 424px) {
				gap: 12px;

				.seperator {
					display: none;
				}

				.count-item {
					.count {
						font-size: 12px;
					}

					.subtext {
						font-size: 10px;
					}
				}
			}
		}
	}

	.max-w-137 {
		max-width: 137px;
	}

	.calendar-item {
		border-radius: 22px;
		background: #60440a;
		display: flex;

		&:not(:last-child) {
			margin-bottom: 30px;
		}

		border: 1px solid transparent;
		transition: all ease 0.3s;

		.cont {
			align-self: center;
			padding: 16px 29px;
			width: 0;
			flex-grow: 1;
			border-right: none;

			@include breakpoint(max-sm) {
				padding: 16px;
			}
		}

		.img {
			width: 150px;
			position: relative;
			margin-right: -1px;
			margin-top: -1px;

			.logo-img {
				position: absolute;
				left: 15px;
				top: 15px;
				z-index: 1;
			}

			.main-img {
				height: calc(100% + 2px);
				object-fit: cover;
				width: 100%;
				border-radius: 0 22px 22px 0;
			}

			@media screen and (max-width: 424px) {
				width: 100px;
			}
		}

		h6 {
			margin-bottom: 9px;
		}

		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.26px;

		a {
			color: #ffc100;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.75px;
		}

		&:hover {
			border-color: #000000;
		}
	}

	.text-13 {
		font-size: 13px;
	}

	.calendar-2 {
		border-radius: 22px;
		background: #7e590b;
		padding: 25px 37px;

		.react-datepicker__day {
			border-radius: 2px;
			background: #674e04;
			margin: 1px !important;
			aspect-ratio: 1;
			max-width: 58px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.react-datepicker__header .react-datepicker__day-names {
			.react-datepicker__day-name {
				color: #000000 !important;
			}
		}

		// .inline-date .react-datepicker .react-datepicker__day--selected {
		// 	border: 1px solid var(--white) !important;
		// 	background: #674e04 !important;
		// }
		.react-datepicker__day-names {
			padding-top: 0 !important;
		}

		@include breakpoint(md) {
			@include breakpoint(max-xl) {
				padding: 25px 20px;
			}
		}

		.react-datepicker__week {

			.react-datepicker__day--in-range,
			.react-datepicker__day:first-child {
				background: #000000 !important;
				color: var(--white) !important;
			}
		}

		.inline-date .react-datepicker .react-datepicker__navigation {
			border-radius: 2px !important;
			background: #674e04 !important;
			border: none !important;
			width: 36px;
			height: 36px;
		}

		.react-datepicker__navigation--previous {
			right: 48px;
			left: unset;
			color: #000000;
		}

		.react-datepicker__current-month {
			text-align: left;
			padding-left: 16px;
		}

		.react-datepicker__current-month {
			margin-bottom: 10px;
		}

		.react-datepicker__day--highlighted-custom-1 {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 7px;
				right: 7px;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: #fff;
			}
		}

		.react-datepicker__day--highlighted-custom-2 {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 7px;
				right: 7px;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: #37ff7b;
			}
		}
	}

	.profile-project-card {
		border-radius: 20px;
		background: linear-gradient(143deg, #885c00 -3.23%, #78540a 105.8%);
		padding: 23px 31px;

		@include breakpoint(max-sm) {
			padding: 16px;
		}
	}

	.trending-feeds-2 {
		.item:not(:last-child) {
			border-bottom: 1px solid #988138;
			margin-bottom: 18px !important;
			padding-bottom: 18px;
		}
	}

	.all-projects {
		.item {
			gap: 16px;
			border-radius: 20px;
			background: #94680e;
			padding: 14px;

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		.img {
			width: 80px;

			img {
				width: 100%;
				border-radius: 9px;
			}
		}

		.cont {
			width: 0;
			flex-grow: 1;

			.name {
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 100%;
				/* 16px */
				letter-spacing: -0.32px;
				// line limit 2
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.info {
				color: #d3a926;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				/* 142.857% */
				letter-spacing: -0.28px;
			}
		}
	}

	.text-14 {
		font-size: 14px;
	}

	.menu-item {
		.active {
			path {
				stroke: #fff !important;
			}
		}
	}

	.menu-item {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			right: -35px;
			height: 100%;
			width: 4px;
			background-color: rgba(255, 199, 70, 1);
			transition: 0.4s;
			transition-delay: 0.3s;
			opacity: 0;
			animation: slideDown 0.7s ease-out;
		}

		@keyframes slideDown {
			from {
				transform: translateY(-100%);
			}

			to {
				transform: translateY(0);
			}
		}

	}

	.sidebar-menu {
		.active::after {
			opacity: 1;
		}

		.active {
			path {
				stroke: #fff !important;
			}
		}
	}

	.dark-light-mode {
		border: 0px solid #FFC100;
		border-radius: 6px;
		padding: 4px;
		max-height: 42px;

		button {
			height: 32px;
			width: 50px;
			display: flex;
			align-items: center;
			border: none;
		}

		.active {
			background: linear-gradient(89.67deg, #FEC44F 0.32%, rgba(255, 193, 0, 0) 133.2%);
			stroke: #fff !important;

			.darkModeIcon {
				path {
					fill: none;
				}
			}

		}
	}

	// @media only screen and (max-width: 600px)
	@media only screen and (max-width: 3840px) {
		.auth-layout .auth-right {
			left: -44px;
		}

		.page_content {
			// max-height: 90vh !important;
		}
	}

	@media only screen and (max-width: 2880px) {
		.auth-layout .auth-right {
			left: -34px;
		}

		.page_content {
			// max-height: 90vh !important;
		}
	}

	@media only screen and (max-width: 1920px) {
		.auth-layout .auth-right {
			left: -20px;
		}

		.page_content {
			// max-height: 82vh !important;
		}
	}

	@media only screen and (max-width: 1450px) {
		.auth-layout .auth-right {
			left: -19px;
		}

		.page_content {
			// max-height: 82vh !important;
		}
	}

	@media only screen and (max-width: 1290px) {
		.auth-layout .auth-right {
			left: -14px;
		}

		.page_content {
			// max-height: 82vh !important;
		}
	}
	.crhEjI{
		background:#FFFFFF;
	}
	.css-8g8ihq{
		.description {
			color: #000000 !important;
		}
	}
	.css-icsobd{
		color: #000000 !important;
	}
	.crhEjI .account-removal .account-removal-content {
		background-color: #FFC100;
		border-radius: 10px;
		p{
			color:#000000;
		}
		.disable-btn{
			background-color: #000000;
			color:#fff;
		}
		.delete-btn{
			border: 1px solid #000000;
			color: #000000;
		}
	}
	.fbyPKn .profile-details-area {
		background: rgb(249, 251, 255);
		border: 1px solid rgba(255, 193, 0, 1);
		border-radius: 16px;
		.top-bar{
			background:#F3F3F5;
			.btn {
				border: 1px solid #000000;
				color: #000000;
			}
			.active{
				border: 1px solid rgba(255, 193, 0, 1);
				color: #fff !important;
			}
		}
		.profile-title{
			.title{
				color: #000000;
			}
		}
	}
	.fbyPKn .profile-information{
		input{
			background: transparent;
			border: 1px solid #000000;
			color: #000000;
			&::placeholder{
				color: #000000;
			}
		}
		 label{
		color: #000000;
		}
	}
	.css-1bxfr63{
		svg{
			fill: #000000;
		}
	}
	.css-4xx2wk{
		svg{
			fill: #000000;
		}
	}
	.fbyPKn .twoFactor-information {
		background-color: #F3F3F5;
		.css-1undcey{
			p{
				color: #000000;
			}
		}
		.form-control{
			background: transparent;
			border: 1px solid #000000 !important;
			color: #000000 !important;
			&::placeholder{
				color: #000000 !important;
			}
		}
		 label{
		color: #000000 !important;
		}
		.canceled-btn{
			color: rgba(255, 193, 0, 1);
		}
	}
	.fbyPKn .payment-title {
		color: #000000;
	}
	.fbyPKn .payment-method {
		color: #000000;
	}
	.fbyPKn .payment-details {
		color: #FFC100;
	}
	.fbyPKn .payment-btn {
		background-color: #FFC100;
	}
	.css-1igwmid{
		 th{
			color:#000000;
		 }
		 td{
			color:#000000;
		 }
	}
	.fbyPKn .your-course .course-items .body-content {
		background-color: #FFC100;
		p{
			color: #000000;
		}
	}
	.fbyPKn .your-course .course-items .body-content .course-time img {
		filter:brightness(0.5)
	}
	.fbyPKn .your-course .course-items .body-content .course-time p{
		color: #000000;
	}
	.fbyPKn .invoice-area .title {
		font-size: 24px;
		font-weight: 600;
		line-height: 27px;
		letter-spacing: 0.05em;
		text-align: left;
		color: #000000;
	}
	.pending-btn {
		color: #000000 !important;
	}
}
